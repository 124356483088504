import './Login.css'
import React, { useEffect, Fragment } from 'react'
import config from '../../config'
import {  authenticatorFactory } from 'genesys-cloud-client-auth';

const Login = () => {
    useEffect(() => {
        console.log('Login component')
        const queryStringData = {
            response_type: 'token',
            client_id: config.clientId,
            redirect_uri: `${window.location.protocol}//${window.location.host}/`
        }
        const param = Object.keys(queryStringData).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryStringData[k])}`).join('&')
        console.log(`Authorization request: ${param}`)
        console.log(sessionStorage.getItem('purecloud-csp-env'))
        var env = sessionStorage.getItem('purecloud-csp-env')
        var pa = sessionStorage.getItem('purecloud-csp-usePopupAuth')
        if (pa === "true") {
            console.info('loading popup auth');
            let re = `${config.appUrl}popuplogin`
            Object.entries(localStorage).forEach(([key, val]) => {
                if (!val.includes('gc-ca')) {
                    console.debug('deleting - ', key)
                    delete localStorage[key]
                };
            });

            const authenticator = authenticatorFactory(config.clientId, {
                /* these are the defaults */
                environment: env,
                persist: false,
                storageKey: 'gc_client_auth_data',
                debugMode: false
            });

            console.info('updated re:', re)

            authenticator.loginImplicitGrant({
                // if left falsy, the `https://apps.{env}/client-auth/` app will be used for the redirectUri
                // else, your redirect will need to implement the parsing of the token (see next step)
                redirectUri: re,
                usePopupAuth: true,
                popupTimeout: 60000 // default amount
                //}).then((data: IAuthData) => {
            }).then((data) => {
                console.log('success')
                /* data will contain authentication information like accessToken */
                sessionStorage.setItem('purecloud-csp-token', data.accessToken)
                localStorage.setItem('purecloud-csp-usePopupAuth', true)
                sessionStorage.setItem('purecloud-csp-usePopupAuth', true)
                window.location.href = `${config.appUrl}?usePopupAuth=true`;
            }).catch(error => {
                console.error('error in popup authentication')
                console.error(JSON.stringify(error))
                /* authentication failed */
                if (error.name === 'TIMEOUT_ERROR') {
                    // this error will trigger after the `popupTimeout` expires without valid auth data saved
                    //  this could be an indication that the user has popups blocked
                    //  you will need to add application logic to handle these errors
                }
            });
        }
        else{
            console.log("No popup")
            window.location = `https://login.${sessionStorage.getItem('purecloud-csp-env')}/oauth/authorize?${param}`
        }
        
    }, [])

    return (<Fragment></Fragment>)
}

export default Login