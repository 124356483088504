import React, { useEffect, Fragment } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { getParameterByName } from '../services/utils'
import purecloud, { getOrganization, getMe } from '../services/purecloud'
import { validateOrg } from '../services/backend'
import config from '../../src/config'
import { authenticatorFactory } from 'genesys-cloud-client-auth';

const Main = () => {
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        const purecloudInitialization = async () => {

            let env = sessionStorage.getItem('purecloud-csp-env')
            if (!env)
                env = new URLSearchParams(location.search).get('environment')
            if (!env)
                env = config.defaultPcEnv

            sessionStorage.setItem('purecloud-csp-env', env)
            console.log('Main.purecloud.init:', env)

            var pa = new URLSearchParams(location.search).get('usePopupAuth')
            sessionStorage.setItem('purecloud-csp-usePopupAuth', pa)


            if (getParameterByName('access_token')) {
                console.log("received the access token------")
                sessionStorage.setItem('purecloud-csp-token', getParameterByName('access_token'))
            }
            else{
                if(!sessionStorage.getItem('validate-token')==true){
                    sessionStorage.removeItem('purecloud-csp-token')
                    sessionStorage.setItem('validate-token', true)
                }
            }

            if (sessionStorage.getItem('purecloud-csp-token')) {
                try {
                    //validate token
                    await getOrganization(sessionStorage.getItem('purecloud-csp-env'), sessionStorage.getItem('purecloud-csp-token')).then(organization => {
                        console.info(`Token is valid`);
                    })
                        .catch(error => {
                            console.error(`Token is invalid`);
                            console.error(error)
                            history.push('/login')
                        });
                    // if(response.success)
                    const customerConfig = await validateOrg(sessionStorage.getItem('purecloud-csp-token'), sessionStorage.getItem('purecloud-csp-env'))
                    console.log("CustomerCofig", customerConfig)
                    console.log('Customer: ', customerConfig.customerVersion)

                    const user = await getMe(sessionStorage.getItem('purecloud-csp-token'), sessionStorage.getItem('purecloud-csp-env'))

                    let hasAFPermission = false

                    if (customerConfig.customerVersion === "AF") {
                        hasAFPermission = user.authorization.permissionPolicies.find(p => p.domain === 'integration' && p.entityName === config.permissionPolicyEntityName) ? true : false
                    
                    } else if (customerConfig.customerVersion === "PS") {
                        hasAFPermission = user.authorization.roles.find(r => r.name === config.provisioningInfo.roles[0].name) ? true : false
                    }

                    console.log('hasAFPermission:', hasAFPermission)
                    sessionStorage.removeItem('validate-token')
                    if (hasAFPermission) {
                        console.log("Org is authorized")
                        history.push('/skills/skillResults')
                        //history.push('/unauthorized', "Unauthorized to access app")
                    }
                    else {
                        console.log("Org is not authorized")
                        history.push('/unauthorized', "Unauthorized to access app")
                    }

                } catch (error) {
                    console.log(error)
                    history.push('/unauthorized', error.message)
                }
            }
            else history.push('/login')
        }

        purecloudInitialization()
    }, [])

    return (<Fragment></Fragment>)
}

export default Main