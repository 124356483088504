
const env = process.env.REACT_APP_CUSTOM_ENV.trim()
console.log('env:', env)

const appName = env === "development" ? "Bulk Skills Administration Dev" : env === "test" ? "Bulk Skills Administration QA" : "Bulk Skills Administration"

const provisioningInfo = {
    roles: [
        {
            name: appName,
            description: 'Generated role for Bulk Skills Administration',
            permissionPolicies: [
                { // permission to allow backend to retrieve all integration types in validate org
                    domain: 'integrations',
                    entityName: 'integration',
                    actionSet: ["view"],
                    allowConditions: false
                },
                {
                    "domain": "authorization",
                    "entityName": "role",
                    "actionSet": ["*"],
                    "allowConditions": false
                },
                {
                    "domain": "routing",
                    "entityName": "language",
                    "actionSet": ["*"],
                    "allowConditions": false
                },
                {
                    "domain": "routing",
                    "entityName": "skill",
                    "actionSet": ["*"],
                    "allowConditions": false
                },
                {
                    "domain": "routing",
                    "entityName": "queue",
                    "actionSet": ["*"],
                    "allowConditions": false
                },
                {
                    "domain": "directory",
                    "entityName": "userProfile",
                    "actionSet": ["edit"],
                    "allowConditions": false
                },
                {
                    "domain": "directory",
                    "entityName": "userProfile",
                    "actionSet": ["view"],
                    "allowConditions": false
                },
                {
                    "domain": "directory",
                    "entityName": "user",
                    "actionSet": ["edit"],
                    "allowConditions": false
                },
                {
                    "domain": "directory",
                    "entityName": "user",
                    "actionSet": ["view"],
                    "allowConditions": false
                },
                {
                    "domain": "integration",
                    "entityName": "bulkSkills",
                    "actionSet": ["*"],
                    "allowConditions": false
                },
                {
                    "domain": "authorization",
                    "entityName": "division",
                    "actionSet": ["*"],
                    "allowConditions": false
                },
                {
                    "domain": "analytics",
                    "entityName": "userDetail",
                    "actionSet": ["View"],
                    "allowConditions": false
                }]
        }
    ],
    oauth: {
        name: appName + ' OAuth',
        description: `Generated OAuth Client that is used by the application backend`,
        authorizedGrantType: 'CLIENT_CREDENTIALS'
    }
};

const development = {
    clientId: '31df7769-6f0f-41ef-9836-af0c1a8096f5',
    //clientId: '8b8addd9-095d-473f-ac62-ae70f7e9a892',
    endpoints: {
        backend: 'https://vt1chzapq1.execute-api.us-east-1.amazonaws.com/dev',
        //backend: 'http://localhost:6000/dev'
    },
    appName: appName,
    appUrl: "https://d22j07zz4y8evu.cloudfront.net/",
    //appUrl: "http://localhost:3000/",
    integrationType: "premium-app-example",
    permissionPolicyEntityName: "examplePremiumApp",
    defaultPcEnv: "mypurecloud.com",
    defaultLangTag: "en-us",
    provisioningInfo: provisioningInfo
}

const qa = {
    clientId: '8b8addd9-095d-473f-ac62-ae70f7e9a892', //DCA Org(bughuntdca)
    endpoints: {
        backend: 'https://lxyavxx61j.execute-api.us-east-1.amazonaws.com/test',
    },
    appName: appName,
    appUrl: "https://d1vduxnnqrqodh.cloudfront.net/",
    integrationType: "premium-app-example",
    permissionPolicyEntityName: "examplePremiumApp",
    defaultPcEnv: "mypurecloud.com",
    defaultLangTag: "en-us",
    provisioningInfo: provisioningInfo
}

const production = {
    clientId: '31df7769-6f0f-41ef-9836-af0c1a8096f5',
    endpoints: {
        backend: 'https://97lhoxl5nj.execute-api.us-east-1.amazonaws.com/prod',
    },
    appName: appName,
    appUrl: "https://bulkskillsadministration.genesyspsapps.com/",
    integrationType: "premium-app-bulkskills",
    permissionPolicyEntityName: "bulkSkills",
    defaultPcEnv: "mypurecloud.com",
    defaultLangTag: "en-us",
    provisioningInfo: provisioningInfo
}

let config
switch (env) {
    case 'production': config = production; break;
    case 'development': config = development; break;
    case 'test': config = qa; break;
    default: break;
}

export default config