import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Error from '@material-ui/icons/Error';
import Close from '@material-ui/icons/Close';
import './AlertModal.css'

const AlertModal = (props) => {
    const { isOpen, toggle, header, body } = props
    return (
        <Dialog fullWidth maxWidth={"xs"} className="alert-modal animated fadeIn" open={isOpen} onClose={toggle}>
            <DialogTitle className="alert-modal-header" ><Error/> <span>{header}</span>
                    <Button style={{float : 'right' }} onClick={toggle}> <Close/></Button></DialogTitle>
            <DialogContent dividers><DialogContentText>{body}</DialogContentText></DialogContent>
        </Dialog>
    )
}

export default AlertModal
