import React, { Component } from 'react';
import '../style.css';
import WizardApp from '../scripts/wizard-app';
import Radio from '@mui/material/Radio';
let bulkSkillsAdministrationApp;

class InstalView extends Component {
    state = {
        selectedRadioButton: ''
      };
    componentDidMount() {
        bulkSkillsAdministrationApp = new WizardApp();
        bulkSkillsAdministrationApp.start();
    };
    

    InstallConfiguration = () => {
        console.log("Installconfig", typeof this.state.selectedRadioButton, this.state.selectedRadioButton.toLowerCase())
        bulkSkillsAdministrationApp.installConfigurations(this.state.selectedRadioButton.toLowerCase()).then(() => {
            this.props.history.push('/finish');
        }).catch((err) => {
            console.log(err)
            if (err && err.body && err.body.message)
                this.props.history.push('/finish/' + err.body.message);
            else
                this.props.history.push('/finish/' + err);
        });
    };

     handleRadioButton =  (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value)
        this.setState({
            selectedRadioButton: event.target.value
          });
    }

    render() {
        return (
            <div className="full-height">
                <div id="background"></div>
                <noscript>
                    For full functionality of this site it is necessary to enable JavaScript. Here are the <a
                        href="http://www.enable-javascript.com/" target="_blank" rel="noopener noreferrer">instructions how to enable JavaScript in your web
                        browser</a>.
                </noscript>

                <header>
                    <p>Bulk Skills Administration /&nbsp; Install</p>
                </header>
                <div className="wiz-content">
                    <div className="title">
                        Installation
                    </div>

                    <ul className="wiz-progress-bar">
                        <li className="active">
                            <span className="txt-start">Start</span>
                        </li>
                        <li className="active current">
                            <span className="txt-install">Install</span>
                        </li>
                        <li className="">
                            <span className="txt-summary">Summary</span>
                        </li>
                    </ul>

                    <main>
                        <p>
                            <span><strong>To assign or remove skills/languages users using Bulk Skills Administration, the users must be a part of a group named "Bulk Skills Administration Users".</strong> 
                                
                            </span>
                        </p>
                        <p>
                            <span className="txt-install-summary">These are the steps that will be automatically performed for
                                you!</span>
                        </p>

                        <div className="message">
                            <div className="message-title">
                                <span className="txt-create-group">1. Create Group</span>
                            </div>
                            <div className="message-content">
                                <div>
                                    <span className="txt-create-group-msg">
                                    Create a group named "Bulk Skills Administration Users".
                                    </span><br/>
                                    <span className="txt-create-group-qsn">
                                    Do you want to add all users of the org to the created group?
                                    </span>
                                    <Radio
                    checked={this.state.selectedRadioButton === 'Yes'}
                    onChange={this.handleRadioButton}
                    value="Yes"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'Yes' }}
                  />Yes
                  <Radio
                    checked={this.state.selectedRadioButton === 'No'}
                    onChange={this.handleRadioButton}
                    value="No"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'No' }}
                  />No
                                </div>
                            </div>
                        </div>

                        <div className="message">
                            <div className="message-title">
                                <span className="txt-create-role">2. Create Roles</span>
                            </div>
                            <div className="message-content">
                                <div>
                                    <span className="txt-create-role-msg">
                                        Create roles specifically to provide access to the app.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="message">
                            <div className="message-title">
                                <span className="txt-create-oauthclient">
                                    3. Create App Instance
                                </span>
                            </div>
                            <div className="message-content">
                                <div>
                                    <span className="txt-create-oauthclient-msg">
                                        Create OAuth Client for server-to-server communication.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="message">
                            <div className="message-title">
                                <span className="txt-create-instance">
                                    4. Configure instance
                                </span>
                            </div>
                            <div className="message-content">
                                <div>
                                    <span className="txt-create-instance-msg">
                                        Configure app instance URI endpoint.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div hidden={this.state.selectedRadioButton === ''}>
                        <p className="sink-more">
                            <span className="txt-start-install">
                                Please click the button to start the installation.
                            </span>
                        </p>

                        <button  id="start" onClick={this.InstallConfiguration.bind(this)} className="wiz-btn-info right">
                            <span className="txt-install">Install</span>
                        </button>
                        </div>
                    </main>
                </div>
                <footer>
                </footer>
            </div>
        )
    }
};

export default InstalView;