import React, { useState, useEffect, Fragment } from 'react';
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from "@material-ui/styles";
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@material-ui/core';
import purecloud from '../../services/purecloud'
import utils from '../../services/utils'
import ConfirmationModal from '../modals/confirmationModal/ConfirmationModal'
import ProgressBarModal from '../modals/progressBarModal/ProgressBarModal'
import backendAPI from '../../services/backend';
import WaitingModal from '../modals/waitingModal/WaitingModal'
import AlertModal from '../modals/alertModal/AlertModal'
import './skillDetailsTable.css'


const SkillDetailsTable = (props) => {
  const token = sessionStorage.getItem('purecloud-csp-token')
  const env = sessionStorage.getItem('purecloud-csp-env')
  const { rowContent, hiddenColumns, styles, ratingSize, entityType, entityID, tableOnClick, resetFilterRatings, resetFilteredMembers, filterBy, filterData } = props
  const defaultPageSize = 25;
  const [initialData, setInitialData] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const useStyles = makeStyles(styles)
  const classes = useStyles();
  const [hiddenButtons, setHiddenButtons] = useState(true)
  const [editedRatingUsers, setEditedRatingUsers] = useState([])
  const [message, setMessage] = useState()
  const [progressBarMax, setProgressBarMax] = useState(0)
  const [progressBarCurrent, setProgressBarCurrent] = useState(0)
  const [hiddenUpdateRating, setHiddenUpdateRating] = useState(true)
  const [value, setValue] = useState(0)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [progressBarHeaderText, setProgressBarHeaderText] = useState()
  const [workInProgress, setWorkInProgress] = useState(false)
  const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
  const [waitingModalHeader, setWaitingModalHeader] = useState('')
  const [error, setError] = useState()
  useEffect(() => {
    console.log('SkillDetailsTable.useEffect.editedRatingUsers:', editedRatingUsers)
    const nEditedUsers = editedRatingUsers.length
    if (nEditedUsers > 0) { // table is in edit mode but the data source has been updated
      setMessage(`${nEditedUsers} users ratings were modified, do you wish to save changes?`)
    } else {
      console.log('SkillDetailsTable.useEffect.Setting data source')
      setInitialData([...rowContent])
      setDataSource(rowContent)
    }
  }, [rowContent])

  const RatingEditInputCell = (props) => {
    const { id, value, api, field } = props;
    const handleChange = (event) => {
      api.setEditCellValue(
        { id, field, value: Number(event.target.value) },
        event
      );
      // Check if the event is not from the keyboard
      // https://github.com/facebook/react/issues/7407
      if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
        api.commitCellChange({ id, field });
        api.setCellMode(id, field, "view");
      }
      setHiddenButtons(false);
      if(JSON.stringify(editedRatingUsers).includes(id))
      {
      editedRatingUsers.forEach(element => {
        if(element.userId===id)
          element.value = Number(event.target.value)
      })
    }
    else
      setEditedRatingUsers([...editedRatingUsers, { userId: id, value: Number(event.target.value) }])
    };


    return (
      <div className={classes.root}>
        <Rating
          name="rating"
          precision={1}
          value={Number(value)}
          onChange={handleChange}
          size={ratingSize}
          style={{ color: "#444a52" }}
        />
      </div>
    );
  }
  const editSkillRatings = async () => {
    console.log('SkillDetailsTable.editSkillRatings:', editedRatingUsers);
    setHiddenButtons(true)
    setMessage(undefined)
    setHiddenUpdateRating(true)
    setWorkInProgress(true)
    setProgressBarHeaderText('Updating ratings...')

    // format GC api call
    let bulkUpdate
    console.log('entityType:', entityType)
    switch (entityType.toLowerCase()) {
      case 'skill': bulkUpdate = editedRatingUsers.map(x => ({ userId: x.userId, proficiency: x.value, skillId: entityID }))
        break;
      case 'language': bulkUpdate = editedRatingUsers.map(x => ({ userId: x.userId, proficiency: x.value, languageId: entityID }))
        break;
      default:
        break;
    }

    // update ratings + retries
    if (bulkUpdate) {
   //   let executionError = false
      let nTotalUpdates = bulkUpdate.length
      setProgressBarMax(nTotalUpdates)
      let nUpdates = 0, response
      while (nUpdates < nTotalUpdates) {
        switch (entityType.toLowerCase()) {
          case 'skill': response = await purecloud.bulkAddOrUpdateUserSkill(token, env, bulkUpdate)
            break;
          case 'language': response = await purecloud.bulkAddOrUpdateUserLanguage(token, env, bulkUpdate)
            break;
          default:
            break;
        }
        nUpdates += response.nResolvedPromises
        setProgressBarCurrent(nUpdates)
        if (response.rejectedPromiseResponse.length > 0) {
          bulkUpdate = response.rejectedPromiseResponse.map(x => x.retry)
          if (response.retryAfterMs) {
          //  console.log("Sleeping for",response.retryAfterMs,"seconds")
            await utils.sleep(response.retryAfterMs)
        }
          // else {
          //   executionError = true
          //   break;
          // }
        }
      }
      // if(executionError)
      //    setProgressBarHeaderText('An Error Occured')
      // else
         setProgressBarHeaderText('Complete!')
      await utils.sleep(2000)
      setProgressBarCurrent(0)
      setProgressBarMax(0)
      setEditedRatingUsers([])
      setWorkInProgress(false)
    }
    try {
      setIsWaitingModalOpen(true)
      setWaitingModalHeader("Loading Members")
      const responses = await backendAPI.getUsers(token, env)
      sessionStorage.setItem('EA-BSA-users', JSON.stringify(responses.users))
      window.location.reload()
    }
    catch (error) {
      setError(`An error occured while retrieving Users:${JSON.stringify(error.message)}`)
      console.log('error:', error)
    }
    finally {
      setIsWaitingModalOpen(false)
    }
  }

  const cancelEditSkillRatings = () => {
    resetFilterRatings()
    console.log(filterBy, filterData)
    filterBy==='all'?(resetFilteredMembers('all', [])):(resetFilteredMembers(filterBy, filterData))
    setEditedRatingUsers([]);
    setValue(0) // Fix for SA-48
    setHiddenButtons(true);
    setMessage(undefined)
    setDataSource([...initialData])
  }

  const tableRowSelected = (params) => {
    setSelectedUsers(params)
    if (params.length > 0)
      setHiddenUpdateRating(false)
    else {
      setHiddenUpdateRating(true)
      if(editedRatingUsers.length>0)
        setHiddenButtons(false) //Fix for SA-48
      else
        setHiddenButtons(true)
    }
    if (tableOnClick) tableOnClick(params)
  }

  const columnheaders = [
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      hide: hiddenColumns && hiddenColumns.find(x => x === 'name') ? true : false
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      hide: hiddenColumns && hiddenColumns.find(x => x === 'email') ? true : false
    },
    {
      field: "division",
      headerName: "Division",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      hide: hiddenColumns && hiddenColumns.find(x => x === 'division') ? true : false
    },
    {
      field: "proficiency",
      headerName: "Rating",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => (
        <div className={classes.root}>
          <Rating readOnly value={params.value} size={ratingSize} style={{ color: "#444a52" }} />
        </div>),
      renderEditCell: RatingEditInputCell,
      editable: true,
      hide: hiddenColumns && hiddenColumns.find(x => x === 'proficiency') ? true : false
    },
  ];

  return (
    <Fragment>
      <WaitingModal
        isOpen={isWaitingModalOpen}
        header={waitingModalHeader}
      />
      <AlertModal
        isOpen={!!error}
        header='Error'
        toggle={() => { setError(undefined) }}
        body={error}
      />
      <ProgressBarModal
        isOpen={workInProgress}
        header={progressBarHeaderText}
        progress={progressBarCurrent}
        min={0}
        max={progressBarMax}
      />
      <ConfirmationModal
        isOpen={!!message}
        header='Ratings updates'
        body={message}
        button1={editSkillRatings}
        button2={cancelEditSkillRatings}
        button1Text='Save'
        button2Text='Discard'
      />
      <DataGrid
        rows={dataSource}
        columns={columnheaders}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[25, 50, 75, 100]}
        pagination
        checkboxSelection
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        onSelectionModelChange={tableRowSelected}
        showColumnRightBorder
      />
      <div style={{ backgroundColor: "#ebf2f8" }}>
        {!hiddenUpdateRating &&
          <Fragment>
            <label> Apply the same rating for the selected members</label><br />
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                /*Fix start for SA-49*/
                console.log('skillDetailsTable.newValue', newValue)
                if (newValue == null) {
                  setValue(0)
                  setHiddenButtons(true)
                }
                /*Fix end for SA-49*/
                else {
                  setValue(newValue);
                  setHiddenButtons(false)
                  console.log(selectedUsers)
                  for (let i = 0; i < selectedUsers.length; i++) {
                    if(JSON.stringify(editedRatingUsers).includes(selectedUsers[i]))
                    {
                      editedRatingUsers.forEach(element => {
                        if(element.userId===selectedUsers[i])
                          element.value = Number(newValue)
                      })
                    }
                    else
                      editedRatingUsers.push({ userId: selectedUsers[i], value: Number(newValue) })

                  }
                  console.log(editedRatingUsers)
                }

              }} style={{ color: "#444a52" }}
            /><br />


          </Fragment>
        }
      </div>
      <div>
        {
          !hiddenButtons &&
          <Fragment>
            <Button variant="contained" style={{ textTransform: "none", marginLeft: 5 }} color = 'primary' onClick={() => {setMessage("Do you want update ratings for "+editedRatingUsers.length+" members")}}> Save </Button>
            <Button variant="contained" style={{ textTransform: "none", marginLeft: 5 }} onClick={cancelEditSkillRatings} > Cancel </Button>
          </Fragment>
        }
      </div>
    </Fragment>
  )
}
export default SkillDetailsTable