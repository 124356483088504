import React, { useState, useEffect } from 'react';
import { FormControl, FormHelperText, MenuItem, Select, TextField, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Close from '@material-ui/icons/Close';


const SkillModal = (props) => {
    const { open, handleClose, modalTitle, contentAvailable, button1, button2, entityName, entityType, membersAssigned, handleSubmit } = props
    const [skillNameEntered, setSkillNameEntered] = useState('')
    const [skillTypeSelected, setSkillTypeSelected] = useState('')
    const [errorName, setErrorName] = useState(false)
    const [errorType, setErrorType] = useState(false)

    const setData = async () => {
        const entityDetails = {};
        if (button1 === "Add" && (!skillNameEntered || !skillTypeSelected)) {
            console.log(skillNameEntered, skillTypeSelected, "skillNameEntered,skillTypeSelected")
            if (!skillNameEntered) setErrorName(true)
            if (!skillTypeSelected) setErrorType(true)
            return;
        }

        if (skillNameEntered)
            entityDetails['skillname'] = skillNameEntered
        else
            entityDetails['skillname'] = entityName

        if (skillTypeSelected)
            entityDetails['skilltype'] = skillTypeSelected.toLowerCase()
        else
            entityDetails['skilltype'] = entityType.toLowerCase()

        setErrorName(false)
        setErrorType(false)
        setSkillNameEntered('')
        setSkillTypeSelected('')
        handleSubmit(entityDetails)
    }

    const resetData = async () => {
        setErrorName(false)
        setErrorType(false)
        setSkillNameEntered('')
        setSkillTypeSelected('')
        handleClose()
    }

    useEffect(() => {

    }, [])

    return (
        <Dialog fullWidth maxWidth="sm" className="skill-modal animated fadeIn" onClose={resetData} open={open}>
            <DialogTitle id="alert-dialog-title">
                {modalTitle}
                <Button style={{float : 'right', textTransform: 'none' }} onClick={resetData}> <Close /> </Button>
            </DialogTitle >
            {contentAvailable ? (
                <DialogContent dividers>
                    <FormControl style={{ width: "100%" }}>
                        <label> Name </label>
                        <TextField size="small" variant="outlined" placeholder="Enter a name"
                            error={errorName}
                            onChange={e => {
                                setSkillNameEntered(e.target.value)
                                setErrorName(false)
                            }} />
                        <br />
                        <label> Type </label>
                        <TextField variant="outlined" size="small" style={{ height: 40}}
                            error={errorType}
                            select={true}
                            SelectProps={{
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }
                            }}
                            onChange={e => {
                                setSkillTypeSelected(e.target.value)
                                setErrorType(false)
                            }}
                        >
                            <MenuItem value="Skill">Skill</MenuItem>
                            <MenuItem value="Language">Language</MenuItem>
                        </TextField>
                    </FormControl>

                </DialogContent>
            ) : (<DialogContent dividers>
                <DialogContentText> There are currently {membersAssigned} members assigned to this {entityType.toLowerCase()}</DialogContentText>
            </DialogContent>)}
            <DialogActions>
                <Button variant="contained" style={{ textTransform: 'none'}}  color = 'primary' onClick={setData}>{button1}</Button>
                <Button variant="contained" style={{ textTransform: 'none' }}  onClick={resetData}>{button2}</Button>
            </DialogActions>
        </Dialog>

    )

}


export default SkillModal