import React, { useEffect, useState } from 'react'
import { Button, Drawer } from '@material-ui/core'
import FilterSelector from '../filtersSelector/FiltersSelector'
import SkillDetailsTable from '../table/skillDetailsTable'
import './AddMembersDrawer.css'

const AddMembersDrawer = (props) => {
    const { open, toggle, anchor, entityID, entityType } = props
    const [data, setData] = useState(undefined)
    const [allUsers, setAllUsers] = useState() // all users that currently own the skill

    useEffect(() => { // initial default filter on all users for that skill
        try {
            console.log(`[${entityID}]`)
            let users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
            console.log('users:', users)
            switch (entityType.toLowerCase()) {
                case 'skill':
                    users = users.filter(x => {
                        const skill = x.skills.find(skill => skill.id === entityID)
                        if (skill) return true
                    }).map(user => ({
                        ...user,
                        division: user.divisions[0].name,
                        proficiency: user.skills.find(x => x.id === entityID).ratings
                    }))
                    break;
                case 'language':
                    users = users.filter(x => {
                        const language = x.languages.find(language => language.id === entityID)
                        if (language) return true
                    }).map(user => ({
                        ...user,
                        division: user.divisions[0].name,
                        proficiency: user.languages.find(x => x.id === entityID).ratings
                    }))
                    break;
                default:
                    break;
            }

            console.log('AddMembersDrawer:users:', users)
            setAllUsers(users)
            setData(users)
        } catch (error) {
            console.log('error:', error)
        }
    }, [])
    const getFilteredData = () => {}

    const filtersChanged = (filterBy, filterData) => {
        console.log('AddMembersDrawer.filtersChanged.filterBy', filterBy)
        console.log('AddMembersDrawer.filtersChanged.filterData', filterData)
        let pendingUsers, users
        switch (filterBy) {
            case 'members':
                pendingUsers = filterData
                    .filter(user => !allUsers.some(x => x.id === user.id)) // removing user from filterList if already present 
                    .map(user => ({
                        ...user,
                        proficiency: 0
                    }))

                console.log('members.pendingUsers:', pendingUsers)
                setData([...pendingUsers, ...allUsers])
                break;
            case 'groups':
                users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
                pendingUsers = users
                    .filter(user => user.groups.some(group => filterData.find(x => x.id === group.id)))
                    .filter(user => !allUsers.some(x => x.id === user.id))
                    .map(user => ({
                        ...user,
                        proficiency: 0
                    }))
                console.log('groups.pendingUser:', pendingUsers)
                setData([...pendingUsers, ...allUsers])
                break;
            case 'divisions':
                users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
                pendingUsers = users
                    .filter(user => user.divisions.some(division => filterData.find(x => x.id === division.id)))
                    .filter(user => !allUsers.some(x => x.id === user.id))
                    .map(user => ({
                        ...user,
                        proficiency: 0
                    }))
                console.log('divisions.pendingUser:', pendingUsers)
                setData([...pendingUsers, ...allUsers])
                break;
            case 'locations':
                users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
                pendingUsers = users
                    .filter(user => user.locations.some(location => filterData.find(x => x.id === location.id)))
                    .filter(user => !allUsers.some(x => x.id === user.id))
                    .map(user => ({
                        ...user,
                        proficiency: 0
                    }))
                console.log('locations.pendingUser:', pendingUsers)
                setData([...pendingUsers, ...allUsers])
                break;
            case 'reportsto':
                users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
                pendingUsers = users
                    .filter(user => user.reportsTo.some(reportsTo => filterData.find(x => x.id === reportsTo.id)))
                    .filter(user => !allUsers.some(x => x.id === user.id))
                    .map(user => ({
                        ...user,
                        proficiency: 0
                    }))
                console.log('reportsto.pendingUser:', pendingUsers)
                setData([...pendingUsers, ...allUsers])
                break;
            case 'roles':
                users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
                pendingUsers = users
                    .filter(user => user.roles.some(role => filterData.find(x => x.id === role.id)))
                    .filter(user => !allUsers.some(x => x.id === user.id))
                    .map(user => ({
                        ...user,
                        proficiency: 0
                    }))
                console.log('roles.pendingUser:', pendingUsers)
                setData([...pendingUsers, ...allUsers])
                break;
            default:
                break;
        }
    }

    return (
        <Drawer open={open} anchor={anchor} onClose={toggle(false)}>
            <FilterSelector
                members={JSON.parse(sessionStorage.getItem('EA-BSA-users'))}
                groups={JSON.parse(sessionStorage.getItem('EA-BSA-groups'))}
                divisions={JSON.parse(sessionStorage.getItem('EA-BSA-divisions'))}
                locations={JSON.parse(sessionStorage.getItem('EA-BSA-locations'))}
                reportsTo={JSON.parse(sessionStorage.getItem('EA-BSA-users'))}
                roles={JSON.parse(sessionStorage.getItem('EA-BSA-roles'))}
                selectedFiltersChanged={filtersChanged}
            />
            <SkillDetailsTable
                rowContent={data}
                hiddenColumns={['email', 'division']}
                styles={{
                    root: {
                        display: "flex",
                        flexDirection: 'column',
                    }
                }}
                ratingSize='large'
                entityType={entityType}
                entityID={entityID}
                resetFilterRatings={getFilteredData} 
          resetFilteredMembers={filtersChanged}
            />
        </Drawer>
    )
}

export default AddMembersDrawer