import React, { useState, useEffect, Fragment } from 'react';

import { makeStyles } from "@material-ui/styles";
import { DataGrid } from '@mui/x-data-grid';


import ProgressBarModal from '../modals/progressBarModal/ProgressBarModal'

import WaitingModal from '../modals/waitingModal/WaitingModal'
import AlertModal from '../modals/alertModal/AlertModal'
import './selectedGroupMembersTable.css'
import Tooltip from '@mui/material/Tooltip';



const SelectedGroupMembersTable = (props) => {

  const { rowContent, hiddenColumns, styles, tableOnClick, selectedGroups } = props
  const defaultPageSize = 25;
  const [initialData, setInitialData] = React.useState([])
  const [dataSource, setDataSource] = useState([])
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const useStyles = makeStyles(styles)
  const classes = useStyles();
  const [hiddenButtons, setHiddenButtons] = useState(true)
  const [editedRatingUsers, setEditedRatingUsers] = useState([])
  const [message, setMessage] = useState()
  const [progressBarMax, setProgressBarMax] = useState(0)
  const [progressBarCurrent, setProgressBarCurrent] = useState(0)
  const [hiddenUpdateRating, setHiddenUpdateRating] = useState(true)
  const [value, setValue] = useState(0)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [progressBarHeaderText, setProgressBarHeaderText] = useState()
  const [workInProgress, setWorkInProgress] = useState(false)
  const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
  const [waitingModalHeader, setWaitingModalHeader] = useState('')
  const [error, setError] = useState()
  useEffect(() => {
    console.log('SelectedGroupMembers.useEffect.groupmembers:', rowContent)
    setDataSource(rowContent)
    console.log('SelectedGroupMembers.useEffect.selectedGroups', selectedGroups)

  }, [rowContent])

  const tableRowSelected = (params) => {
    setSelectedUsers(params)
    if (params.length > 0)
      setHiddenUpdateRating(false)
    else {
      setHiddenUpdateRating(true)
      if (editedRatingUsers.length > 0)
        setHiddenButtons(false) //Fix for SA-48
      else
        setHiddenButtons(true)
    }
    if (tableOnClick) tableOnClick(params)
  }




  const columnheaders = [
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      resizable: false,
      minWidth: 50,
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      hide: hiddenColumns && hiddenColumns.find(x => x === 'name') ? true : false
    },
    {
      field: "selectedGroups",
      headerName: "Group Name",
      sortable: false,
      resizable: false,
      minWidth: 200,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      hide: hiddenColumns && hiddenColumns.find(x => x === 'groups') ? true : false,
    },
    {
      field: "division",
      headerName: "Division",
      sortable: false,
      resizable: false,
      minWidth: 25,
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      hide: hiddenColumns && hiddenColumns.find(x => x === 'division') ? true : false
    },
    {
      field: "skills",
      headerName: "Skills and Languages",
      renderCell: (params) => (
        <div className={classes.root}>
          {(() => {
            let result = '';
            if (params.row.skills) {
              for (let i = 0; i < params.row.skills.length; i++)
                result === '' ? result = params.row.skills[i].name : result = result + ', ' + params.row.skills[i].name
            }
            if (params.row.languages) {
              for (let i = 0; i < params.row.languages.length; i++)
                result === '' ? result = params.row.languages[i].name : result = result + ', ' + params.row.languages[i].name
            }
            let from3Element = []
            if (result.split(',').length > 4) {
              for (let i = 4; i < result.split(',').length; i++)
                from3Element = from3Element.length === 0 ? result.split(',')[i] : from3Element + ',' + result.split(',')[i]

              return (
                <div>
                  {result.split(',')[0] + ',' + result.split(',')[1] + ',' + result.split(',')[2] + ',' + result.split(',')[3] + ','}
                  <Tooltip title={String(from3Element)}>
                    <a href='#'>+{from3Element.split(',').length}</a>
                  </Tooltip>
                </div>
              )
            }
            else
              return (<div>{result}</div>);
          })()}
        </div>),
      sortable: false,
      resizable: false,
      minWidth: 150,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      hide: hiddenColumns && hiddenColumns.find(x => x === 'proficiency') ? true : false
    },
  ];

  return (
    <Fragment>
      <WaitingModal
        isOpen={isWaitingModalOpen}
        header={waitingModalHeader}
      />
      <AlertModal
        isOpen={!!error}
        header='Error'
        toggle={() => { setError(undefined) }}
        body={error}
      />
      <ProgressBarModal
        isOpen={workInProgress}
        header={progressBarHeaderText}
        progress={progressBarCurrent}
        min={0}
        max={progressBarMax}
      />

      {!hiddenColumns.length > 0 &&
        <DataGrid
          rows={dataSource}
          columns={columnheaders}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 75, 100]}
          pagination
          checkboxSelection
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          onSelectionModelChange={tableRowSelected}
          showColumnRightBorder
          localeText={{
            noRowsLabel: 'Add groups to begin assigning skills and languages'
          }}
        />
      }
      {hiddenColumns.length > 0 &&
        <DataGrid
          rows={dataSource}
          columns={columnheaders}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 75, 100]}
          pagination
          checkboxSelection
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          onSelectionModelChange={tableRowSelected}
          showColumnRightBorder
          localeText={{
            noRowsLabel: 'Search for a group to add and view members'
          }}
        />
      }
    </Fragment>
  )
}
export default SelectedGroupMembersTable