import React, { Fragment, useEffect, useState } from 'react'
import { Button, Drawer, useScrollTrigger } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, FormControl, MenuItem, Select } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from "@material-ui/styles";
import Radio from '@mui/material/Radio';
import purecloud from '../../services/purecloud'
import ConfirmationModal from '../modals/confirmationModal/ConfirmationModal'
import utils from '../../services/utils'
import ProgressBarModal from '../modals/progressBarModal/ProgressBarModal'
import backendAPI from '../../services/backend';
import WaitingModal from '../modals/waitingModal/WaitingModal'
import AlertModal from '../modals/alertModal/AlertModal'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import GroupsSelector from '../groupsSelector/GroupsSelector'
import SelectedGroupMembersTable from '../table/selectedGroupMembersTable'
import './RemoveSkillsDrawer.css'


const RemoveSkillsDrawer = (props) => {
    const token = sessionStorage.getItem('purecloud-csp-token')
    const env = sessionStorage.getItem('purecloud-csp-env')
    const { open, toggle, anchor, selectedAgents, allSkillsLanguages, toggleCloseDrawer, modifiedAgentSkills, groupsUsers, enableFinishButton } = props
    const [pageSize, setPageSize] = useState(25)
    const [type, setType] = useState('All Selected')
    const [commonEntities, setCommonEntities] = useState(allSkillsLanguages)
    const [filterData, setfilterData] = useState(allSkillsLanguages)
    const [allEntities, setAllEntitites] = useState(allSkillsLanguages)
    const [filtertext, setFilterText] = useState('')
    const [selectedSkilledUsers, setSelectedSkilledUsers] = useState([])
    const [allSelectedSkilledUsers, setAllSelectedSkilledUsers] = useState([])
    const [editedRatingUsers, setEditedRatingUsers] = useState([])
    const [selectedUsersforSkillEdit, setSelectedUsersforSkillEdit] = useState([])
    const [message, setMessage] = useState()
    const [data, setData] = useState({ undefined })
    const [allUsers, setAllUsers] = useState()
    const [selectedUsers, setSelectedUsers] = useState([])
    const [hiddenUpdateRating, setHiddenUpdateRating] = useState(true)
    const [numberOfSelectedUsers, setNumberOfSelectedUsers] = useState([])
    const [selectedGroups, setSelectedGroups] = useState([])
    const [disableAdd, setDisableAdd] = useState(true)
    const [selectedSkill, setSelectedSkill] = useState([])
    const [progressBarCurrent, setProgressBarCurrent] = useState(0)
    const [progressBarMax, setProgressBarMax] = useState(0)
    const [value, setValue] = useState(0)
    const [hiddenButtons, setHiddenButtons] = useState(true)
    const [skillSelected, setSkillSelected] = useState(true)
    const [progressBarHeaderText, setProgressBarHeaderText] = useState()
    const [workInProgress, setWorkInProgress] = useState(false)
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [waitingModalHeader, setWaitingModalHeader] = useState('')
    const [error, setError] = useState()
    const [membersToDelete, setMembersToDelete] = useState([]);
    const [noSkillSelected, setNoSkillSelected] = useState(true)
    const styles = {
        root: {
            display: "flex",
            flexDirection: 'column',
        }
    }
    const temp = [
        {
            "id": "fa963bcf-2a58-4365-9889-d3dd453e5685",
            "name": "ARay",
            "type": "skill",
            "memberCount": 24
        },
        {
            "id": "5d5041fe-d67f-4880-9254-0875eed102fd",
            "name": "Account_Manager",
            "type": "skill",
            "memberCount": 12
        },
        {
            "id": "38a5e173-671e-4db3-a7fb-88ec550ed3e5",
            "name": "Acelera",
            "type": "skill",
            "memberCount": 11
        }]

    const useStyles = makeStyles(styles)
    const classes = useStyles();
    let radioChecked = [];
    const [nextButtonClicked, setNextButtonClicked] = useState(false)
    const [selectionModel, setSelectionModel] = React.useState(radioChecked);
    radioChecked = selectionModel;
    const selectedRow = filterData.filter((item) => {
        return item.id === selectionModel[0];
    });

    let selectedMembers = []

    useEffect(() => { // initial default filter on all users for that skill
        try {

            //Arrange common skills first
            let selectedUsersSkillLanguages = []; let selectedUsersSkillLanguagesId = []; let commonEntities = [];

            for (let i = 0; i < selectedAgents.length; i++) {
                //parse users to find the common skills and assign to commonSkills
                let users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
                selectedUsersSkillLanguages = [...selectedUsersSkillLanguages, ...users
                    .find(user => user.id === selectedAgents[i]).skills, ...users
                        .find(user => user.id === selectedAgents[i]).languages]

            }


            // setSelectedMembersEntities(selectedUsersSkillLanguages)
            for (let i = 0; i < selectedUsersSkillLanguages.length; i++)
                selectedUsersSkillLanguagesId = [...selectedUsersSkillLanguagesId, selectedUsersSkillLanguages[i].id]

            let unique = new Set(selectedUsersSkillLanguagesId)

            let selectedMembersEntities = []
            for (const element of unique)
                selectedMembersEntities = [...selectedMembersEntities, ...allEntities.filter(x => x.id === element)]


            if (selectedAgents.length === 1)
                commonEntities = [...commonEntities, ...selectedUsersSkillLanguagesId]
            else {
                for (let i = 0; i < selectedUsersSkillLanguagesId.length; i++) {
                    for (let j = i + 1; j < selectedUsersSkillLanguagesId.length; j++) {
                        if (selectedUsersSkillLanguagesId[i] === selectedUsersSkillLanguagesId[j])
                            commonEntities = [...commonEntities, selectedUsersSkillLanguagesId[i]]
                    }
                }
            }


            //common skills first
            let sort = []
            for (let i = 0; i < commonEntities.length; i++) {
                sort = [...sort, ...allEntities.filter(skill => skill.id === commonEntities[i])]
            }


            let all = allEntities
            //delete commonskills in filterdata and append to the beginning
            for (let i = 0; i < all.length; i++) {
                for (let j = 0; j < selectedMembersEntities.length; j++) {

                    if (all[i].id === selectedMembersEntities[j].id) {
                        all = all.filter((item) => item.id !== all[i].id);
                    }

                }
            }
            let selectedMembersSkills = selectedMembersEntities.filter(x => x.type === "skill")
            selectedMembersSkills = selectedMembersSkills.sort(function (a, b) {
                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            })
            //get selectedMembersEntities languages and sort
            let selectedMembersLanguages = selectedMembersEntities.filter(x => x.type === "language")
            selectedMembersLanguages = selectedMembersLanguages.sort(function (a, b) {
                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            })
            selectedMembersEntities = [...selectedMembersSkills, ...selectedMembersLanguages]
            all = [...selectedMembersEntities, ...all]
            //setAllEntitites(all)

            setfilterData(selectedMembersEntities)
            setCommonEntities(selectedMembersEntities)
            //assign === false ? setfilteredData(selectedMembersEntities) : setfilteredData(all);
        } catch (error) {
            console.error('error:', error)
        }
    }, [allSkillsLanguages])
    console.log("RemoveSkillsDDrawer: Remove skills", filterData)


    const columns = [
        {
            field: "",
            headerName: "",
            width: 50,
            sortable: false,
            renderCell: (params) => (
                <Radio checked={radioChecked[0] === params.id} value={params.id} />
            )
        },
        {
            field: "name",
            headerName: "Name",
            sortable: false,
            resizable: false,
            minWidth: 100,
            flex: 0.65,
            type: 'string',
            headerClassName: 'super-app-theme--header'

        },
        {
            field: "type",
            headerName: "Type",
            sortable: false,
            resizable: false,
            minWidth: 100,
            flex: 0.35,
            type: 'string',
            headerClassName: 'super-app-theme--header'

        },
    ];



    const handleCloseDrawer = () => {
        setSelectionModel([])
        setNextButtonClicked(false)
        toggleCloseDrawer(false)
        setType('All Selected')
        setfilterData(commonEntities)
        setNoSkillSelected(true)
    }


    const cancelRemoveSkills = async () => {
        setMessage()
    }
    const removeSkills = async () => {
        console.info("RemoveSKillsDrawer.RemoveSkills, Skill: ", selectedRow[0], "Users: ", selectedAgents, 'GroupMembers: ', groupsUsers)
        for (let i = 0; i < selectedAgents.length; i++) {
            membersToDelete.push({ userId: selectedAgents[i] })
        }
        setMessage()
        //console.log("AssignSkills: Skill:", selectedRow[0], "Users: ", editedRatingUsers)
        setNoSkillSelected(true)
        setWorkInProgress(true)
        setProgressBarHeaderText('Removing ' + selectedRow[0].type + ' from user(s)...')

        let bulkUpdate
        if (selectedRow[0].type === 'skill')
            bulkUpdate = membersToDelete.map(x => ({ userId: x.userId, skillId: selectedRow[0].id }))
        if (selectedRow[0].type === 'language')
            bulkUpdate = membersToDelete.map(x => ({ userId: x.userId, languageId: selectedRow[0].id }))


        if (bulkUpdate) {
            //   let executionError = false
            let nTotalUpdates = bulkUpdate.length
            setProgressBarMax(nTotalUpdates)
            let nUpdates = 0, response
            while (nUpdates < nTotalUpdates) {
                if (selectedRow[0].type === 'skill')
                    response = await purecloud.bulkRemoveSkillFromUser(token, env, bulkUpdate)
                if (selectedRow[0].type === 'language')
                    response = await purecloud.bulkRemoveLanguageFromUser(token, env, bulkUpdate)
                nUpdates += response.nResolvedPromises
                setProgressBarCurrent(nUpdates)
                if (response.rejectedPromiseResponse.length > 0) {
                    bulkUpdate = response.rejectedPromiseResponse.map(x => x.retry)
                    if (response.retryAfterMs) {
                        console.log("Sleeping for", response.retryAfterMs, "seconds")
                        await utils.sleep(response.retryAfterMs)
                    }

                }
            }
            setProgressBarHeaderText(`${selectedAgents.length} members successfully updated! Click Finish when skill modifications are complete`)
            await utils.sleep(2000)
            setProgressBarCurrent(0)
            setProgressBarMax(0)
            setEditedRatingUsers([])
            setWorkInProgress(false)
            setNextButtonClicked(false)
            setSelectionModel([])
            //toggleCloseDrawer(false)

        }
        try {
            const promises = [
                backendAPI.getUsers(token, env),
                backendAPI.getDynamicGroups(token, env)
            ]
            setIsWaitingModalOpen(true)
            setWaitingModalHeader("Loading Members")
            const responses = await Promise.all(promises)
            for (const response of responses) {
                switch (response.id) {
                    case 'users': sessionStorage.setItem('EA-BSA-users', JSON.stringify(response.users))
                        break;
                    case 'dynamicgroups': sessionStorage.setItem('EA-BSA-dynamicgroups', JSON.stringify(response.dynamicGroups))
                        break;
                    default:
                        break;
                }
            }
            let users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
            let groupsMembers = JSON.parse(sessionStorage.getItem('EA-BSA-SelectedGroupMembers'))
            for (let i = 0; i < groupsMembers.length; i++) {
                for (let j = 0; j < users.length; j++) {
                    if (groupsMembers[i].id === users[j].id) {
                        users[j].selectedGroups = groupsMembers[i].selectedGroups
                    }
                }
            }
            let usersAfterSkillChange = new Array()
            for (let i = 0; i < users.length; i++) {
                for (let j = 0; j < groupsUsers.length; j++) {
                    if (groupsUsers[j].id === users[i].id) {
                        usersAfterSkillChange.push(users[i])
                    }
                }
            }
            let sortUsersAfter = new Array()
            for (let i = 0; i < groupsMembers.length; i++) {
                sortUsersAfter.push(usersAfterSkillChange.find(x => x.id === groupsMembers[i].id))
            }
            console.log("RemoveSkillsDrawer.UsersAfterSkillChange", usersAfterSkillChange)
            modifiedAgentSkills([])
            modifiedAgentSkills(sortUsersAfter)
            setIsWaitingModalOpen(false)
        }
        catch (error) {
            setError(`An error occured while retrieving Users:${JSON.stringify(error.message)}`)
            console.error('error:', error)
        }
        finally {
            setIsWaitingModalOpen(false)
        }
        toggleCloseDrawer(false)
        enableFinishButton(true)
    }


    return (
        <Drawer open={open} anchor={anchor} onClose={toggle(false)}>
            <ConfirmationModal
                isOpen={!!message}
                header='Remove Skill'
                body={message}
                button1={removeSkills}
                button2={cancelRemoveSkills}
                button1Text='Save'
                button2Text='Discard'
            />
            <ProgressBarModal
                isOpen={workInProgress}
                header={progressBarHeaderText}
                progress={progressBarCurrent}
                min={0}
                max={progressBarMax}
            />
            <WaitingModal
                isOpen={isWaitingModalOpen}
                header={waitingModalHeader}
            />
            <AlertModal
                isOpen={!!error}
                header='Error'
                toggle={() => { setError(undefined) }}
                body={error}
            />
            <Fragment>
                <h5 style={{ marginLeft: 5 }}>Remove Skills and Languages</h5>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDrawer}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Fragment>
                    <Grid container spacing={1} style={{
                        margin: 0,
                        width: '100%'
                    }}>
                        <Grid item >
                            <FormControl variant="outlined" >
                                <TextField variant="outlined" size="small" style={{ minWidth: 150, width: "80%" }}
                                    value={type}
                                    select={true}
                                    SelectProps={{
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }
                                    }}
                                    onChange={e => {
                                        setType(e.target.value)
                                        setfilterData(commonEntities.filter(x => (x.type === e.target.value.toLowerCase()) && (x.name.toLowerCase().includes(filtertext.toLowerCase()))))
                                        if (e.target.value === "All Selected") {
                                            setfilterData(commonEntities.filter(x => x.name.toLowerCase().includes(filtertext.toLowerCase())))
                                        }
                                    }}>
                                    <MenuItem value="All Selected" >All Selected</MenuItem>
                                    <MenuItem value="Skill">Skill</MenuItem>
                                    <MenuItem value="Language">Language</MenuItem>
                                </TextField>
                            </FormControl>
                        </Grid>

                        <Grid item  >
                            <TextField style={{ width: "120%" }}

                                InputProps={{
                                    endAdornment: (
                                        <SearchIcon style={{ color: "#e0e0e0" }} />
                                    )
                                }}
                                label="Search"
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                onChange={e => {
                                    setFilterText(e.target.value)
                                    if (type !== "All Selected")
                                        setfilterData(commonEntities.filter(x => (x.name.toLowerCase().includes(e.target.value.toLowerCase())) && (x.type === type.toLowerCase())))
                                    else
                                        setfilterData(commonEntities.filter(x => (x.name.toLowerCase().includes(e.target.value.toLowerCase()))))
                                }}
                            />
                        </Grid>
                    </Grid>

                    <DataGrid
                        rows={filterData}
                        columns={columns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[25, 50, 75, 100]}
                        pagination
                        disableColumnFilter
                        disableColumnMenu
                        hideFooterSelectedRowCount
                        showColumnRightBorder
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        selectionModel={selectionModel}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                            setNoSkillSelected(false)
                        }}
                    //onCellClick={currentlySelected}
                    />
                    <div>
                        <Button disabled={noSkillSelected} variant="contained" color='primary' style={{ textTransform: 'none', float: 'left', margin: 5 }} onClick={() => { setMessage("Do you want to remove " + selectedAgents.length + " member(s) from the selected skill?") }}>Next</Button>
                        <Button variant="contained" style={{ textTransform: 'none', float: 'left', margin: 5 }} onClick={handleCloseDrawer}>Cancel</Button>
                    </div>
                </Fragment>
            </Fragment>

        </Drawer>
    )
}

export default RemoveSkillsDrawer