import React from 'react';
import { Button } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import './ConfirmationModal.css'

const ConfirmationModal = (props) => {
    const { isOpen, header, body, button1, button2, button1Text, button2Text } = props
    return (
        <Dialog fullWidth maxWidth={"xs"} className="confirmation-modal animated fadeIn" open={isOpen}>
            <DialogTitle className="confirmation-modal-header" >
                <CheckCircle /> {header}</DialogTitle>
            <DialogContent>{body}</DialogContent>
            <DialogActions>
                <div >
                    {button1 ? <Button variant = 'outlined' color="primary" onClick={button1}>{button1Text ? button1Text : 'OK'}</Button> : null}
                </div>
                <div >
                    {button2 ? <Button variant = 'outlined' color="secondary" onClick={() => button2()}>{button2Text ? button2Text : 'Cancel'}</Button> : null}
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationModal
