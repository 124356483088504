import React from 'react'
import { Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import Login from '../components/login/Login'
import Main from '../components/Main'
import PopUpLogin from '../components/PopUpLogin';
import HelpView from '../components/help/HelpView'
import Unauthorized from '../components/unauthorized/Unauthorized'
import SkillResultsView from '../components/skills/skillResults/SkillResultsView'
import SkillDetailsPage from '../components/skills/skillDetails/SkillDetailsPage'

import Index from '../wizard/components/IndexView'
import Install from '../wizard/components/InstallView'
import Finish from '../wizard/components/FinishView'

const history = createBrowserHistory()
const AppRouter = () => (
    <Router history={history}>
        <Switch>
            <PublicRoute path="/" exact={true} component={Main} history={history} />
            <PublicRoute path="/popuplogin" component={PopUpLogin} history={history} />
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/unauthorized" component={Unauthorized} />
            <PublicRoute path="/help" component={HelpView} />
            <PrivateRoute path="/skills/skillResults" component={SkillResultsView} history={history} />
            <PrivateRoute path="/skills/SkillDetailsPage" component={SkillDetailsPage} history={history} />

            <PublicRoute path="/index.html" component={Index} history={history} />
            <PrivateRoute path="/install" component={Install} history={history} />
            <PrivateRoute path="/finish/:errorMessage?" component={Finish} history={history} />
        </Switch>
    </Router>
)

export default AppRouter