import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Header from '../../header/Header';
import './SkillResultsView.css'
import SkillModal from '../../modals/skillModal/SkillModal'
import { DataGrid } from '@mui/x-data-grid';
import { TextField, FormControl, MenuItem } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import backendAPI from '../../../services/backend';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Menu from '@material-ui/core/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import AlertModal from '../../modals/alertModal/AlertModal'
import ConfirmationModal from '../../modals/confirmationModal/ConfirmationModal'
import WaitingModal from '../../modals/waitingModal/WaitingModal'
import Grid from '@material-ui/core/Grid';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import SelectedGroupMembersTable from '../../table/selectedGroupMembersTable'
import AddGroupsDrawer from '../../addGroupsDrawer/AddGroupsDrawer';
import AddSkillsDrawer from '../../addSkillsDrawer/AddSkillsDrawer';
import RemoveSkillsDrawer from '../../removeSkillsDrawer/RemoveSkillsDrawer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete } from '@material-ui/lab'


const SkillResultsView = () => {
  const [pageSize, setPageSize] = useState(25)
  const history = useHistory()
  const [allEntities, setAllEntitites] = useState([])
  const [allSkillLanguages, setAllSkillLanguages] = useState([])
  const [selectedMembersEntities, setSelectedMembersEntities] = useState([])
  const [open, setOpen] = useState(false);
  const [modalTitle, setModaltitle] = useState("");
  const [contentAvailable, setContentAvailable] = useState(true)
  const [button1, setButton1] = useState('')
  const [button2, setButton2] = useState('')
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState('All Selected')
  const [filteredData, setfilteredData] = useState([])
  const [membersAssigned, setMembersAssigned] = useState(0)
  const [eventTriggered, setEventTriggered] = useState('')
  const [entityType, setEntityType] = useState()
  const [entityName, setEntityName] = useState()
  const [entityID, setEntityID] = useState('')
  const [filtertext, setFilterText] = useState('')
  const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
  const [waitingModalHeader, setWaitingModalHeader] = useState('')
  const [message, setMessage] = useState()
  const [cancelMessage, setCancelMessage] = useState()
  const [error, setError] = useState()
  const [value, setValue] = useState("skills")
  const [filteredUsers, setFilteredUsers] = useState([])
  const [selectedAgents, setSelectedAgents] = useState([])
  const [selectedUsersForSkillChange, setSelectedUsersForSkillChange] = useState([])
  const token = sessionStorage.getItem('purecloud-csp-token')
  const env = sessionStorage.getItem('purecloud-csp-env')
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openSkillsDrawer, setOpenSkillsDrawer] = useState(false)
  const [disableAssignRemoveButton, setDisableAssignRemoveButton] = useState(true)
  const [openSkillsDrawerRemove, setOpenSkillsDrawerRemove] = useState(false)
  const [skillChangesComplete, setSkillChangesComplete] = useState(true)
  const [selectedGroups, setSelectedGroups] = useState([])
  const [autoCompleteValue, setAutoCompleteValue] = useState([])
  const [dynamicgroupsEnabled, setDynamicgroupsEnabled] = useState(false)

  // initial load of data
  useEffect(() => {

    const fetchData = async () => {
      try {
        console.log('SkillResultsView.fetchData.begin')
        setIsWaitingModalOpen(true)
        const promises = [
          backendAPI.getDynamicGroups(token, env),
          backendAPI.getUsers(token, env),
          backendAPI.getDivisions(token, env),
          backendAPI.getRoles(token, env),
          backendAPI.getLocations(token, env),
          backendAPI.getGroups(token, env),
          getAllEntitites()
        ]

        setWaitingModalHeader("Loading Skills and Languages")
        const responses = await Promise.all(promises)
        console.log('SkillResultsView.fetchData.end')
        for (const response of responses) {
          switch (response.id) {
            case 'users': sessionStorage.setItem('EA-BSA-users', JSON.stringify(response.users))
              break;
            case 'locations': sessionStorage.setItem('EA-BSA-locations', JSON.stringify(response.locations))
              break;
            case 'groups': sessionStorage.setItem('EA-BSA-groups', JSON.stringify(response.groups))
              break;
            case 'dynamicgroups': sessionStorage.setItem('EA-BSA-dynamicgroups', JSON.stringify(response.dynamicGroups))
              break;
            case 'roles': sessionStorage.setItem('EA-BSA-roles', JSON.stringify(response.roles))
              break;
            case 'divisions': sessionStorage.setItem('EA-BSA-divisions', JSON.stringify(response.divisions))
              break;
            default:
              break;
          }
        }
        if (JSON.stringify(responses.find(x=>x.id === "users").users) === undefined) {
          throw new Error("No users found. Please add the users to the group 'Bulk Skills Administration Users' to assign skills and languages");
        }
        setDynamicgroupsEnabled(true)
        console.log('SkillResultsView.fetchData.loadComplete')
        
      }
      catch (error) {
        /*Handle Orgs which does not have dynamic groups enabled*/
        console.log(error.message)
        if (error.message === 'This endpoint is not yet implemented,') {
          try {
            console.log('SkillResultsView.fetchData.begin')
            setIsWaitingModalOpen(true)
            const promises = [
              backendAPI.getUsers(token, env),
              backendAPI.getDivisions(token, env),
              backendAPI.getRoles(token, env),
              backendAPI.getLocations(token, env),
              backendAPI.getGroups(token, env),
              getAllEntitites()
            ]

            setWaitingModalHeader("Loading Skills and Languages")
            const responses = await Promise.all(promises)
            console.log('SkillResultsView.fetchData.end')
            for (const response of responses) {
              switch (response.id) {
                case 'users': sessionStorage.setItem('EA-BSA-users', JSON.stringify(response.users))
                  break;
                case 'locations': sessionStorage.setItem('EA-BSA-locations', JSON.stringify(response.locations))
                  break;
                case 'groups': sessionStorage.setItem('EA-BSA-groups', JSON.stringify(response.groups))
                  break;
                case 'roles': sessionStorage.setItem('EA-BSA-roles', JSON.stringify(response.roles))
                  break;
                case 'divisions': sessionStorage.setItem('EA-BSA-divisions', JSON.stringify(response.divisions))
                  break;
                default:
                  break;
              }
            }
            if (JSON.stringify(responses.find(x=>x.id === "users").users) === undefined) {
              throw new Error("No users found. Please add the users to the group 'Bulk Skills Administration Users' to assign skills and languages");
            }
            sessionStorage.setItem('EA-BSA-dynamicgroups', sessionStorage.getItem('EA-BSA-groups'))
            setDynamicgroupsEnabled(false)
            console.log('SkillResultsView.fetchData.loadComplete')
          }
          catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.error("Error in SkillResultsView: ", error)
          }
        }
        else {
          setError(`An error occured :${JSON.stringify(error.message)}`)
          console.error("Error in SkillResultsView: ", error)
        }
      } finally {
        setIsWaitingModalOpen(false)
      }
    }
    fetchData()
  }, [])

  const toggleCloseDrawer = (close) => {
    setOpenDrawer(close)
    setOpenSkillsDrawer(close)
    setOpenSkillsDrawerRemove(close)
  }
  const toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open)
  }

  const toggleSkillsDrawer = (open) => async (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenSkillsDrawer(open)
  }
  const toggleRemoveSkillsDrawer = (open) => async (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenSkillsDrawerRemove(open)
  }
  console.log("Sorted All Entities: ", allEntities)

  const handleClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);
  const handleClosePop = () => { setAnchorEl(null) };
  const handleSubmit = (entity) => {
    if (entityID) {
      entity['entityID'] = entityID;
    }
    if (eventTriggered === "add_skill_language")
      addNewEntity(entity)
    if (eventTriggered === "delete_skill_language")
      deleteExistingEntity(entity)
    handleClose()
  }

  const getAllEntitites = async () => {
    try {
      //setIsWaitingModalOpen(true)
      setWaitingModalHeader("Loading Skills and Languages")
      const entitiesDataResult = await backendAPI.getEntities(token, env)
      setAllEntitites(entitiesDataResult.entities)
      setAllSkillLanguages(entitiesDataResult.entities)
      setfilteredData(entitiesDataResult.entities)
      if (filtertext || type !== "All Selected")
        setfilteredData(entitiesDataResult.entities.filter(x => (x.type === type.toLowerCase()) && (x.name.toLowerCase().includes(filtertext.toLowerCase()))))
      if (type === "All Selected" && filtertext)
        setfilteredData(entitiesDataResult.entities.filter(x => (x.name.toLowerCase().includes(filtertext.toLowerCase()))))
      sessionStorage.setItem('EA-BSA-entities', entitiesDataResult.entities)
      return entitiesDataResult
    }
    catch (error) {
      setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
      console.error(error)
    } finally {
      // setIsWaitingModalOpen(false)
    }
  }


  const addNewEntity = async (entity) => {
    try {
      setIsWaitingModalOpen(true)
      setWaitingModalHeader(`Creating new ${entity.skilltype} "${entity.skillname}"`)
      const addEntitiesDataResult = await backendAPI.addEntity(token, env, entity)
      console.debug("SkillResultsView.addNewEntity",addEntitiesDataResult)
      setIsWaitingModalOpen(false)
      setMessage(`The ${entity.skilltype} "${entity.skillname}" has been created successfully`)
      setTimeout(() => {
        getAllEntitites()
        setMessage()
      }, 2000);
    } catch (error) {
      setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
      console.error(error)
    } finally {
      setIsWaitingModalOpen(false)
    }
  }

  const deleteExistingEntity = async (entity) => {
    try {
      setIsWaitingModalOpen(true)
      setWaitingModalHeader(`Deleting the ${entity.skilltype} "${entity.skillname}"`)
      const deleteEntitiesDataResult = await backendAPI.deleteEntity(token, env, entity)
      console.log(deleteEntitiesDataResult)
      setIsWaitingModalOpen(false)
      setMessage(`The ${entity.skilltype} "${entity.skillname}" has been deleted successfully`)
      setTimeout(() => {
        getAllEntitites()
        setMessage()
      }, 2000);
    } catch (error) {
      setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
      console.error(error)
    } finally {
      setIsWaitingModalOpen(false)
    }
  }

  const currentlySelected = (params) => {
    const value = params.colDef.field;
    if (value !== "actions")
      return;
    if (params.getValue(params.id, 'type') === 'skill')
      setEntityType('Skill')
    if (params.getValue(params.id, 'type') === 'language')
      setEntityType('Language')
    setEntityName(params.getValue(params.id, 'name'))
    setEntityID(params.id)
    setMembersAssigned(params.getValue(params.id, 'memberCount'))
  };

  const currentlySelectedMembers = (params) => {
    setAllEntitites([])
    setSelectedUsersForSkillChange(params)
    if (params.length > 0)
      setDisableAssignRemoveButton(false)
    else
      setDisableAssignRemoveButton(true)
  }

  const selectedGroupsforSearch = (event, value) => {
    setAutoCompleteValue(value, selectedAgents)
    if (value.length > 0) {
      let filteredMembers = new Set()
      for (let i = 0; i < value.length; i++) {
        if(value[i].type === "dynamic")
        {
          for (let k = 0; k < selectedAgents.length; k++) {
            if (selectedAgents[k].selectedGroups.includes(value[i].name) )
              filteredMembers.add(selectedAgents[k])
          }
      }
      else
      {
        for(let l=0;l<selectedAgents.length;l++)
        {
          for(let m=0; m<selectedAgents[l].groups.length; m++)
          {
            if(selectedAgents[l].groups[m].id === value[i].id)
              filteredMembers.add(selectedAgents[l])
          }
        }
      }
      }
      filteredMembers = [...filteredMembers]
      console.info("SkillResultView.selectedGroupsForSearch: GroupsSelection", filteredMembers)
      setFilteredUsers(filteredMembers)
    }
    else
      setFilteredUsers(selectedAgents)

  }

  const columnheaders = [
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.65,
      type: 'string',
      headerClassName: 'super-app-theme--header'

    },
    {
      field: "type",
      headerName: "Type",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.35,
      type: 'string',
      headerClassName: 'super-app-theme--header'

    },
    {
      field: "memberCount",
      headerName: "Members Assigned",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.35,
      type: 'number',
      headerClassName: 'super-app-theme--header'

    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: 300,
      renderCell: (params) => (
        <strong>
          <div>
            <Button size="small" style={{ textTransform: 'none' }} onClick={e => { setAnchorEl(e.currentTarget) }}>
              <MoreVert />
            </Button>
          </div>
        </strong>
      )
    }
  ];

  const handleSkillsGroups = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
    console.log("SkillResultsView.HandleSkillsGroups: Current tab is ", newValue)

  }

  const selectedGroupmembers = (selectedmembers, selectedGroups) => {
    console.info('SkillResultsView.selectedGroupMembers: ', selectedmembers, selectedGroups)
    selectedmembers = selectedmembers.map(user => ({
      ...user,
      division: user.divisions[0].name,
      //group: user.groups.find(x => x.id === group.id).name
    }))
    setFilteredUsers(selectedmembers)
    setSelectedAgents(selectedmembers)
    setSelectedGroups(selectedGroups)
  }

  function updateAgentSkills(selectedmembers) {
    console.info('SkillResultsView.selectedGroupMembers: ', selectedmembers)
    selectedmembers = selectedmembers.filter(x=>x != undefined)
    selectedmembers = selectedmembers.map(user => ({
      ...user,
      division: user.divisions[0].name,
      //group: user.groups.find(x => x.id === group.id).name
    }))
    setFilteredUsers(selectedmembers)
    setSelectedAgents(selectedmembers)
  }

  const enableFinishButton = () => {
    setSkillChangesComplete(false)

  }
  const cancelGroupAssignment = () => {
    setFilteredUsers([])
    setSkillChangesComplete(true)
    setCancelMessage()
    setSelectedGroups([])
    setAutoCompleteValue([])
  }
  const handleCloseCancelDialog = () => {
    setCancelMessage()
  }

  return (
    <div >
      <Header />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleSkillsGroups} aria-label="lab API tabs example">
              <Tab label="Skill Management" value="skills" />
              <Tab label="Group Assignment" value="groups" />
            </TabList>
          </Box>
          <TabPanel value="skills">
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClosePop}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => {
                history.push({
                  pathname: '/skills/SkillDetailsPage',
                  state: { entityName, entityID, entityType }
                })
              }
              }>Assign members</MenuItem>

              <MenuItem onClick={() => {
                setModaltitle(`Do you want to delete this ${entityType.toLowerCase()}?`)
                setContentAvailable(false)
                setButton1('Yes')
                setButton2('No')
                handleClickOpen()
                handleClosePop()
                setEventTriggered("delete_skill_language")
              }}>Delete {entityType}</MenuItem>

            </Menu>
            <SkillModal
              open={open}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              modalTitle={modalTitle}
              contentAvailable={contentAvailable}
              button1={button1}
              button2={button2}
              membersAssigned={membersAssigned}
              entityType={entityType}
              entityName={entityName}
            />
            <WaitingModal
              isOpen={isWaitingModalOpen}
              header={waitingModalHeader}
            />
            <ConfirmationModal
              isOpen={!!message}
              header='Success!'
              toggle={() => { setMessage(undefined) }}
              body={message}
            />

            <AlertModal
              isOpen={!!error}
              header='Error'
              toggle={() => { setError(undefined) }}
              body={error}
            />
            <Grid container spacing={2} style={{
              margin: 0,
              width: '100%'
            }}>
              <Grid item >
                <FormControl variant="outlined" >
                  <TextField variant="outlined" size="small" style={{ minWidth: 150, width: "100%", marginLeft: '5%' }}
                    value={type}
                    select={true}
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }
                    }}
                    onChange={e => {
                      setType(e.target.value)
                      setfilteredData(allSkillLanguages.filter(x => (x.type === e.target.value.toLowerCase()) && (x.name.toLowerCase().includes(filtertext.toLowerCase()))))
                      if (e.target.value === "All Selected") {
                        setfilteredData(allSkillLanguages.filter(x => x.name.toLowerCase().includes(filtertext.toLowerCase())))
                      }
                    }}>
                    <MenuItem value="All Selected" >All Selected</MenuItem>
                    <MenuItem value="Skill">Skill</MenuItem>
                    <MenuItem value="Language">Language</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>

              <Grid item xs={8} >
                <TextField style={{ width: "60%" }}

                  InputProps={{
                    endAdornment: (
                      <SearchIcon style={{ color: "#e0e0e0" }} />
                    )
                  }}
                  label="Search skills and languages"
                  defaultValue=""
                  variant="outlined"
                  size="small"
                  onChange={e => {
                    setFilterText(e.target.value)
                    if (type !== "All Selected")
                      setfilteredData(allSkillLanguages.filter(x => (x.name.toLowerCase().includes(e.target.value.toLowerCase())) && (x.type === type.toLowerCase())))
                    else
                      setfilteredData(allSkillLanguages.filter(x => (x.name.toLowerCase().includes(e.target.value.toLowerCase()))))
                  }}
                />
              </Grid>

              <Grid item xs>
                <Button variant="contained" style={{ textTransform: 'none', float: 'right', marginRight: '5%' }} onClick={() => {
                  setModaltitle("Add Skill or Language")
                  setContentAvailable(true)
                  setButton1('Add')
                  setButton2('Cancel')
                  handleClickOpen()
                  setEventTriggered("add_skill_language")
                }}><AddCircleIcon />Add Skill/Language</Button>
              </Grid>
            </Grid>

            <div id='container-resultsview'>
              <Grid item xs >
                <DataGrid
                  rows={filteredData}
                  columns={columnheaders}
                  pageSize={pageSize}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                  pagination
                  disableColumnFilter
                  disableColumnMenu
                  hideFooterSelectedRowCount
                  showColumnRightBorder
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onCellClick={currentlySelected}
                />
              </Grid>

            </div>
          </TabPanel>
          <TabPanel value="groups">
            <h5>Assign Skills and Languages to Groups</h5>
            <Grid container spacing={0} style={{
              margin: 0,
              width: '100%'
            }}>
              <Dialog
                open={!!cancelMessage}
                onClose={handleCloseCancelDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Cancel Progress?"}
                  <IconButton
                    aria-label="close"
                    onClick={handleCloseCancelDialog}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {cancelMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" color='primary' onClick={cancelGroupAssignment} autoFocus>Yes</Button>
                  <Button color='primary' onClick={handleCloseCancelDialog} >
                    No
                  </Button>
                </DialogActions>
              </Dialog>

              <Autocomplete style={{ width: "40%" }}
                multiple
                id="combo-box-demo"
                value={autoCompleteValue}
                options={selectedGroups}
                getOptionLabel={(option) => option.name}
                sx={{ width: 300 }}
                onChange={(event, newValue) => { selectedGroupsforSearch(event, newValue) }}
                renderInput={(params) => <TextField {...params} label="Search" />}
              />

              <div style={{ float: "right", marginLeft: 500, right: 0 }}>
                <Button variant="contained" style={{ textTransform: 'none', margin: 5 }}
                  disabled={disableAssignRemoveButton}
                  onClick={toggleRemoveSkillsDrawer(true)}
                >
                  Remove</Button>
                <RemoveSkillsDrawer
                  open={openSkillsDrawerRemove}
                  toggle={toggleRemoveSkillsDrawer}
                  anchor='right'
                  selectedAgents={selectedUsersForSkillChange}
                  allSkillsLanguages={allEntities}
                  toggleCloseDrawer={toggleCloseDrawer}
                  modifiedAgentSkills={updateAgentSkills}
                  groupsUsers={filteredUsers}
                  enableFinishButton={enableFinishButton}
                />

                <Button variant="contained" style={{ textTransform: 'none', margin: 5 }}
                  disabled={disableAssignRemoveButton}
                  onClick={toggleSkillsDrawer(true)}
                >
                  Assign</Button>
                <AddSkillsDrawer
                  open={openSkillsDrawer}
                  toggle={toggleSkillsDrawer}
                  anchor='right'
                  selectedAgents={selectedUsersForSkillChange}
                  allSkillsLanguages={allEntities}
                  toggleCloseDrawer={toggleCloseDrawer}
                  modifiedAgentSkills={updateAgentSkills}
                  groupsUsers={filteredUsers}
                  enableFinishButton={enableFinishButton}
                />

                <Button variant="contained" style={{ textTransform: 'none', margin: 5 }} onClick={toggleDrawer(true)} >
                  <AddCircleIcon />Add Groups</Button>
                <AddGroupsDrawer
                  open={openDrawer}
                  toggle={toggleDrawer}
                  anchor='right'
                  selectedAgents={selectedGroupmembers}
                  toggleCloseDrawer={toggleCloseDrawer}
                  dynamicgroupsEnabled={dynamicgroupsEnabled}
                />
              </div>
            </Grid>
            <div hidden={true}>
              Group names
            </div>
            <hr />
            <div id='container-resultsview-groups'>
              <Grid item xs >
                <SelectedGroupMembersTable
                  rowContent={filteredUsers}
                  hiddenColumns={[]}
                  styles={{
                    root: {
                      display: "flex",
                      flexDirection: 'column',
                    }
                  }}
                  tableOnClick={currentlySelectedMembers}
                />
              </Grid>
              <div style={{ marginTop: 10 }} hidden={!filteredUsers.length > 0}>
                <Button disabled={skillChangesComplete} variant="contained" style={{ textTransform: 'none' }} onClick={cancelGroupAssignment}>Finish</Button>
                <Button disabled={!skillChangesComplete} variant="contained" style={{ textTransform: 'none', marginLeft: 10 }} onClick={() => { setCancelMessage("Canceling will lose any selections made." + "\n" + "Would you like to cancel the process?") }}>Cancel</Button>
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </div>

  )

}



export default SkillResultsView