import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from "@material-ui/core/LinearProgress";
import CheckCircle from '@material-ui/icons/CheckCircle';
import './ProgressBarModal.css'

const ProgressBarModal = (props) => {
    const { isOpen, header, progress, min, max } = props

    const normalize = value => ((value - min) * 100) / (max - min);

    return (
        <Dialog fullWidth maxWidth={"xs"} className="progress-modal--centered animated fadeIn" open={isOpen}>
            <DialogTitle className="progress-modal--centered"> {header} </DialogTitle>
            <DialogContent dividers className="progress-modal--centered">
                {progress < max ?
                    <LinearProgress variant="determinate" value={normalize(progress)} />
                    : <CheckCircle />
                }
            </DialogContent>
        </Dialog>
    )
}

export default ProgressBarModal;
