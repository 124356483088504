import React, { Component } from 'react';
import '../style.css';
import WizardApp from '../scripts/wizard-app';

let bulkSkillsAdministrationApp;

class FinishView extends Component {
    constructor() {
        super();

        this.state = {
            successClass: "hidden",
            failureClass: "hidden"
        };
    }

    componentDidMount() {
        bulkSkillsAdministrationApp = new WizardApp();
        bulkSkillsAdministrationApp.start();
        const error = this.props.match.params.errorMessage;

        if (error) {
            this.setState({ errorMessage: error });
            this.setState({ failureClass: "" });
            this.setState({ successClass: "hidden" });
        }
        else {
            this.setState({ failureClass: "hidden" });
            this.setState({ errorMessage: "" });
            this.setState({ successClass: "" });
        }
    };

    render() {
        return (
            <div className="full-height">
                    <header>
                        <p>Bulk Skills Administration /&nbsp; Summary</p>
                    </header>

                <div id="background"></div>

                <noscript>
                    For full functionality of this site it is necessary to enable JavaScript. Here are the <a
                        href="http://www.enable-javascript.com/" target="_blank" rel="noopener noreferrer">instructions how to enable JavaScript in your web
            browser</a>.
    </noscript>

                <canvas id="bubbly-layer" style={{ position: "fixed", top: "0", left: "0", zIndex: "-5" }}></canvas>

                <div className="wiz-content">
                    <div className="title">
                        Done
                </div>

                    <ul className="wiz-progress-bar">
                        <li className="active">
                            <span className="txt-start">Start</span>
                        </li>
                        <li className="active">
                            <span className="txt-install">Install</span>
                        </li>
                        <li className="active current">
                            <span className="txt-summary">Summary</span>
                        </li>
                    </ul>
                    <main>

                        <div id="installed" className={this.state.successClass}>
                            <p className="success">
                                <span className="txt-product-installed">Product installed</span> <i
                                    className="fas fa-check-circle enlarge"></i>
                            </p>
                            <p>
                                <span className="txt-finish-install">
                                Bulk Skills Administration application installation has completed successfully.
                    </span>
                            </p>
                            <button id="next" className="wiz-btn-info right" onClick={() => this.props.history.push('/')}>
                                <span className="txt-open">Open</span></button>
                        </div>

                        <div id="error" className={this.state.failureClass}>
                            <p className="fail">
                                <span className="txt-product-error">Product could not be installed</span> <i
                                    className="fas fa-exclamation-circle enlarge"></i>
                            </p>
                            <p>
                                <span id="errormessage" className="txt-error-message">
                                    {this.state.errorMessage}
                                </span>
                            </p>
                        </div>

                    </main>
                </div>
                <footer style={{ marginTop: "1%" }}>
                </footer>
            </div>
        )
    }
};

export default FinishView;