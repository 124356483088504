/*
*   NOTE: This sample uses ES6 features
*/
import config from '../../../src/config'
import backend from '../../services/backend'
const platformClient = window.require('platformClient');

const appConfig = config;
// JQuery Alias
const $ = window.$;

// Relative path to wizard page from config's redirectUri
//const WIZARD_PAGE = "/index.html";

const pageSize = 500;
/**
 * WizardApp class that handles everything in the App.
 */
class WizardApp {
    constructor(redirectUri) {
        this.pcEnvironment = null;
        this.org = null;

        // PureCloud Javascript SDK clients
        this.platformClient = platformClient;
        this.purecloudClient = this.platformClient.ApiClient.instance;
        this.purecloudClient.setPersistSettings(true, appConfig.integrationType);
        this.redirectUri = appConfig.appUrl + "index.html";

        // PureCloud API instances
        this.usersApi = new this.platformClient.UsersApi();
        this.integrationsApi = new this.platformClient.IntegrationsApi();
        this.authApi = new this.platformClient.AuthorizationApi();
        this.oAuthApi = new this.platformClient.OAuthApi();
        this.organizationApi = new this.platformClient.OrganizationApi()

        // Language default is english
        // Language context is object containing the translations
        this.language = appConfig.defaultLangTag;

        this.integrationType = appConfig.integrationType;

        this.appName = appConfig.appName;
        this.appUrl = appConfig.appUrl;

        this.installationData = appConfig.provisioningInfo;
    }

    //// =======================================================
    ////      ENTRY POINT
    //// =======================================================
    start() {
        return new Promise((resolve, reject) => {
            this._setupClientApp()
                .then(() => {
                    return this._pureCloudAuthenticate()
                })
                .then((data) => {
                    // console.log('start, data = ', JSON.stringify(data, null, 3));
                    if (data && data.accessToken) {
                        sessionStorage.setItem('purecloud-csp-token', data.accessToken);
                    }
                    console.log('Setup success');
                    return this.organizationApi.getOrganizationsMe()
                }).then((orgData) => {
                    this.org = orgData
                    return resolve();
                })
                .catch((err) => {
                    console.log(err);
                    reject(err)
                });
        });
    }

    /**
     * First thing that needs to be called to setup up the PureCloud Client App
     */
    _setupClientApp() {
        // Snippet from URLInterpolation example: 
        // https://github.com/MyPureCloud/client-app-sdk
        const queryString = window.location.search.substring(1);
        const pairs = queryString.split('&');

        let pcEnv = null;
        let langTag = null;

        for (let i = 0; i < pairs.length; i++) {
            const currParam = pairs[i].split('=');

            if (currParam[0] === 'langTag') {
                langTag = currParam[1];
            } else if (currParam[0] === 'environment') {
                pcEnv = currParam[1];
            }
        }

        // Stores the query parameters into sessionStorage
        // If query parameters are not provided, try to get values from sessionStorage
        // Default values if it does not exist.
        if (pcEnv) {
            sessionStorage.setItem('purecloud-csp-env', pcEnv);
        } else if (sessionStorage.getItem('purecloud-csp-env')) {
            pcEnv = sessionStorage.getItem('purecloud-csp-env');
        } else {
            // Use default PureCloud region
            pcEnv = appConfig.defaultPcEnv;
            sessionStorage.setItem('purecloud-csp-env', pcEnv);
        }

        this.pcEnvironment = pcEnv;
        console.log("Environment:" + this.pcEnvironment);

        if (langTag) {
            sessionStorage.setItem('purecloud-csp-langTag', langTag);
        } else if (sessionStorage.getItem('purecloud-csp-langTag')) {
            langTag = sessionStorage.getItem('purecloud-csp-langTag');
        } else {
            // Use default Language
            langTag = appConfig.defaultLangTag;
        }
        this.language = langTag;

        console.log("Language:" + this.language);

        // Get the language context file and assign it to the app
        // For this example, the text is translated on-the-fly.
        return new Promise((resolve, reject) => {
            const fileUri = '../languages/' + this.language + '.json';
            $.getJSON(fileUri)
                .done(data => {
                    this.displayPageText(data);
                    resolve();
                })
                .fail(xhr => {
                    reject(new Error(`Language file not found - "${this.language}.json"`));
                });
        });
    }

    /**
    * Renders the proper text language into the web pages
    * @param {Object} text  Contains the keys and values from the language file
    */
    displayPageText(text) {
        $(document).ready(() => {
            for (let key in text) {
                if (!text.hasOwnProperty(key)) continue;
                $("." + key).text(text[key]);
            }
        });
    }

    /**
     * Authenticate to PureCloud (Implicit Grant)
     * @return {Promise}
     */
    _pureCloudAuthenticate() {
        console.log('_pureCloudAuthenticate clientId =', appConfig.clientId);
        console.log('_pureCloudAuthenticate redirectUri =', this.redirectUri);
        console.log('_pureCloudAuthenticate pcEnvironment =', this.pcEnvironment);

        this.purecloudClient.setEnvironment(this.pcEnvironment);
        return this.purecloudClient.loginImplicitGrant(
            appConfig.clientId,
            this.redirectUri,
            { state: ('pcEnvironment=' + this.pcEnvironment) });
    }

    /**
    * Checks if the product is available in the current Purecloud org by
    * validating the integration type being enabled for the org
    * @return {Promise.<Boolean>}
    */
    validateProductAvailability() {
        // premium-app-usertemplates         
        return this.getEntities(this.integrationsApi, 'getIntegrationsTypes')
            .then((entities) => {
                // console.log('Integration types: ' + JSON.stringify(entities, null, 3));

                if (entities.filter((integType) => integType.id === this.integrationType)[0]) {
                    console.log("PRODUCT AVAILABLE");
                    return (true);
                } else {
                    console.log("PRODUCT NOT AVAILABLE");
                    return (false);
                }
            });
    }

    /**
    * Checks if any configured objects are still existing. 
    * This is based on the appName
    * @returns {Promise.<Boolean>} If any installed objects are still existing in the org. 
    */
    isExisting() {
        const promiseArr = [];

        promiseArr.push(this.getExistingRoles());
        promiseArr.push(this.getExistingAuthClients());

        return Promise.all(promiseArr)
            .then((results) => {
                console.log('isExisting, results = ' + JSON.stringify(results, null, 3));
                return results[0].length && results[1].length;
            });
    }

    /**
     * Get existing roles in purecloud based on appName
     * @returns {Promise.<Array>} PureCloud Roles
     */
    getExistingRoles() {
        const authOpts = {
            'name': appConfig.appName.replace(/\(/g, '\\(').replace(/\)/g, '\\)') + '*', // Wildcard to work like STARTS_WITH, need to escape ) and (
            'userCount': false
        };

        // console.log('Get existing roles: ' + JSON.stringify(authOpts, null, 3));
        return this.getEntities(this.authApi, 'getAuthorizationRoles', authOpts);
    }

    /**
     * Get existing authetication clients based on the prefix
     * @returns {Promise.<Array>} Array of PureCloud OAuth Clients
     */
    getExistingAuthClients() {
        return this.getEntities(this.oAuthApi, 'getOauthClients')
            .then((entities) => {
                //console.log('getExistingAuthClients, entities = ' + JSON.stringify(entities, null, 3));
                return entities.filter(entity => entity.name && entity.name.startsWith(this.appName));
            });
    }

    /**
     * Get details of the current user
     * @return {Promise.<Object>} PureCloud User data
     */
    getUserDetails() {
        const opts = { 'expand': ['authorization'] };
        return this.usersApi.getUsersMe(opts);
    }

    //// =======================================================
    ////      PROVISIONING
    //// =======================================================

    /**
     * Final Step of the installation wizard. 
     * Create the PureCloud objects defined in provisioning configuration
     * The order is important for some of the PureCloud entities.
     */
    installConfigurations(addusers) {
        console.log('Installing configurations');
        let addUsers = addusers
        $.LoadingOverlay("show", {
            image: "",
            fontawesomeResizeFactor: 2.5,
            fontawesome: "fa fa-spinner fa-spin",
            fontawesomeColor: '#DDDDDD',
            fontawesomeOrder: 1,
            text: "Configuring your App",
            textResizeFactor: 0.17,
            textOrder: 2,
            textClass: "adjust-text",
            background: "rgba(240, 240, 255, 0.9)"
        });

        // Get the app instance from integration
        return this.getAppInstance()
            //Create Group
             .then(() => this.addGroup(addUsers))
            // Create OAuth client after role (required) and pass to server
            .then(() => this.addRole())

            // Create OAuth client after role (required) and pass to server
            .then((role) => this.addAuthClient(role))
            //.then((oAuthClients) => this.storeOAuthClient(oAuthClients))

            // Add organization in User Templates configuration
            .then((oauth) => this.addOrganization(oauth))

            // Update User Templates app instance url
            .then(() => this.updateAppInstance())

            // When everything's finished, log a success message.
            .then(() => {
                this.logInfo('Installation Complete!');
                setTimeout(function () { $.LoadingOverlay("hide"); }, 1500);
            }).catch((err) => {
                this.logInfo('Error occured!');
                setTimeout(function () { $.LoadingOverlay("hide"); }, 1500);
                throw err;
            });
    }

    getAppInstance() {
        return this.getEntities(this.integrationsApi, 'getIntegrations')
            .then(entities => {
                // console.log('Integrations: ' + JSON.stringify(entities, null, 3));

                return (entities.find(entity => entity.integrationType.id === this.integrationType && entity.name === this.appName));
            })
            .then(integration => {
                if (!(integration && integration.id)) {
                    throw new Error(`Could not find integration - "${this.appName}".`);
                }
                return integration;
            });
    }

    /**
     * Add PureCLoud Group named Bulk Skills Administration Users
     * @returns {Promise}
     */
     async addGroup(addUsers) {
        console.log('Adding group');
        this.logInfo('Creating group Bulk Skills Administration Users');
        const token = sessionStorage.getItem('purecloud-csp-token')
    const env = sessionStorage.getItem('purecloud-csp-env')
        // Create the group
        
        try {
             await backend.addGroup(token, env, addUsers);
             this.logInfo('Created group Bulk Skills Administration Users');
         } catch (err) {
             console.log('Group creation error:' + JSON.stringify(err, null, 3));
             throw err;
         }
    }

    /**
     * Add PureCLoud role based on installation data
     * @returns {Promise}
     */
    addRole() {
        console.log('Adding role');
        this.logInfo('Creating role ' + appConfig.appName);

        const roleBody = {
            name: this.installationData.roles[0].name,
            description: this.installationData.roles[0].description,
            permissionPolicies: this.installationData.roles[0].permissionPolicies
        };

        let role;

        // Create the role
        return this.authApi.postAuthorizationRoles(roleBody)
            .then((data) => {
                this.logInfo('Created role ' + appConfig.appName);
                role = data;
                return this.getUserDetails();
            })
            .then((user) => {
                // Assign the role to the user
                // Required before you can assign the role to an Auth Client.
                return this.authApi.putAuthorizationRoleUsersAdd(role.id, [user.id]);
            })
            .then(() => {
                this.logInfo('Assigned role ' + appConfig.appName + ' to user');
                return role;
            })
            .catch((err) => {
                console.log('Role creation error:' + JSON.stringify(err, null, 3));
                throw err;
            });
    }

    /**
     * Add PureCLoud instance based on installation data
     * @returns {Promise.<Array>} PureCloud OAuth objects
     */
    addAuthClient(role) {
        console.log('Adding oauth client');
        this.logInfo('Creating oauth client');

        const oauthClient = {
            name: this.installationData.oauth.name,
            description: this.installationData.oauth.description,
            roleIds: [role.id],
            authorizedGrantType: "CLIENT_CREDENTIALS"
        };

        return this.oAuthApi.postOauthClients(oauthClient)
            .then((data) => {
                this.logInfo(`Created OAuth client "${this.installationData.oauth.name}"`);
                return data;
            })
            .catch((err) => {
                console.log('Oauth creation error:' + JSON.stringify(err, null, 3));
                throw err;
            })
    }

    //// =======================================================
    ////      ORGANIZATION
    //// =======================================================

    addOrganization(oauth) {
        console.log('Adding organization');
        this.logInfo('Adding org');

        console.log(appConfig.endpoints.backend + '/addOrg/' + this.org.id)

        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST',
                headers: {
                    'token': sessionStorage.getItem('purecloud-csp-token'),
                    'env': this.pcEnvironment,
                    'tokensource': 'purecloud'
                },
                url: appConfig.endpoints.backend + '/addOrg/' + this.org.id,
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify({ clientId: oauth.id, clientSecret: oauth.secret, orgName: this.org.name, version: 'AF' }),
                success: function(result) {
                    console.log('Added org');
                    resolve()
                },
                error: function (xhr, status, error) {
                    console.log('addOrganization, xhr = ' + JSON.stringify(xhr, null, 3));
                    console.log('addOrganization, status = ' + JSON.stringify(status, null, 3));
                    console.log('addOrganization, error = ' + JSON.stringify(error, null, 3));

                    reject("Error in adding organisation.");
                }
            });
        });
    }


    updateAppInstance() {
        console.log('Update "%s", url = %s', this.appName, this.appUrl);
        this.logInfo('Updating App instance');

        return this.getAppInstance()
            .then(integration => {
                // console.log('App instance: ' + JSON.stringify(integration, null, 3));

                return Promise.all([integration.id, this.integrationsApi.getIntegrationConfigCurrent(integration.id)]);
            })
            .then(values => {
                console.log('App instance current config: ' + JSON.stringify(values[1], null, 3));

                const integrationsOpts = {
                    body: values[1]  // integration config current
                };
                integrationsOpts.body.properties.url = this.appUrl + '?environment={{pcEnvironment}}';

                console.log('App instance new config: ' + JSON.stringify(integrationsOpts, null, 3));

                return this.integrationsApi.putIntegrationConfigCurrent(values[0], integrationsOpts);
            });
    }

    //// =======================================================
    ////      DISPLAY/UTILITY FUNCTIONS
    //// =======================================================

    /**
     * Shows an overlay with the specified data string
     * @param {string} data 
     */
    logInfo(data) {
        if (!data || (typeof (data) !== 'string')) data = "";

        $.LoadingOverlay("text", data);
    }


    //// =======================================================
    ////      UTILITIES
    //// =======================================================

    getEntities(api, func, options) {
        const entities = [];
        let pageNumber = 1;

        const getEntitiesWorker = () => {
            return api[func]({ ...options, pageSize, pageNumber })
                .then((data) => {
                    console.log(`${func}, pageSize = ${pageSize}, pageNumber = ${pageNumber}, entities = ${data.entities.length}`);
                    entities.push(...data.entities);
                    return (pageNumber++ < data.pageCount) ? getEntitiesWorker() : entities;
                });
        }
        return getEntitiesWorker();
    }

}


export default WizardApp;