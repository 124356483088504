import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Header from '../../components/header/Header';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './HelpView.css'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '2%'
  },
  border: {
    border: '1px solid  #ccc',
    borderRadius: '2px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: 'bold',
    flexBasis: '33.33%',
    flexShrink: 0,
  }
}));

const HelpView = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState('1');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  // initial load of data
  useEffect(() => {

  }, [])

  return (
    <div >
      <Header />
      <Container>
        <div className={classes.root}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                  <Tab label="Help" value="1" />
                  <Tab label="Release Notes" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <p>
                  The Bulk Skills Administration App is used to assign Skills or Languages to Genesys Cloud Users in bulk. <b>To assign or remove skills/languages to a user, the user must be a part of the group named "Bulk Skills Administration Users"</b>. The app has two ways of assigning Skills or Languages
                  <ol>&nbsp;<li>Skill Management – Select a skill and assign/remove members in bulk to that skill</li>
                    <li>Group Assignment – Select a group and assign/remove a skill in bulk to the members of that group
                    </li></ol>
                </p>
                <br />
                <h5><strong>Skill Management</strong></h5>


                <Accordion pt={3} expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.border}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>Adding a Skill</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Container>
                        <ol>
                          <li>Click the Add Skill/Language button</li>
                          <li>A popup is shown with the option to enter the name of the skill and select the type of the skill.</li>
                          <li>Enter the values and click Add to save the details</li>
                        </ol>
                      </Container>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className={classes.border}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography className={classes.heading}>Deleting a Skill</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Container>
                        <lo>
                          <li>On the Skill list, Click the icon under the Actions Column</li>
                          <li>Select Delete Skill</li>
                          <li>A confirmation dialogue opes asking for confirmation for deletion</li>
                          <li>Click Yes to delete the skill</li>
                        </lo>
                      </Container>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className={classes.border}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography className={classes.heading}>Assigning Users to a Skill</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Container>
                        <lo>
                          <li>On the Skill list, Click the icon under the Actions Column</li>
                          <li>Click Assign Members</li>
                          <li>The page is redirected to the list of Members currently assigned to the Skill</li>
                          <li>Click Add Members button on the right</li>
                          <li>A slider opens, listing the available members.</li>
                          <li>Select the required filter and check the members to whom the Skill is to be assigned</li>
                          <li>On, selection the rating appears in the bottom of the page</li>
                          <li>Select the rating and click Save to assign the Skill</li>
                          <li>When multiple members are selected, same rating is applied to all members</li>
                        </lo>
                      </Container>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className={classes.border}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography className={classes.heading}>Removing Users from a Skill</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Container>
                        <ol>
                          <li>On the Skill list, Click the icon under the Actions Column</li>
                          <li>Click Assign Members</li>
                          <li>The page is redirected to the list of Members currently assigned to the Skill</li>
                          <li>Select the members to be removed from Skill</li>
                          <li>Click the Remove User button and a confirmation dialogue opens</li>
                          <li>Click yes to remove the skill from user</li>
                        </ol>
                      </Container>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <hr />
                <h5><strong>Group Management</strong></h5>
                <Accordion pt={3} expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className={classes.border}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                  >
                    <Typography className={classes.heading}>Add Groups</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Container>
                        <ol>
                          <li>Click the Add Groups button</li>
                          <li>A drawer is displayed. Select the type of group and filter for group names</li>
                          <li>The members of the group will be added. Select the members and click Add button to confirm selection of group members</li>
                        </ol>
                      </Container>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className={classes.border}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                  >
                    <Typography className={classes.heading}>Add Members to Skill</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Container>
                        <lo>
                          <li>On the Members list, select the members for whom skill needs to be added</li>
                          <li>Select Add Skill</li>
                          <li>A drawer opens up with the list of skills and languages</li>
                          <li>Select the skill and click Next</li>
                          <li>Select the rating for the skill either by clicking on the star for an individual member or select users and choose a common rating below</li>
                          <li>Click Add to add the skill with the selected ratings</li>
                        </lo>
                      </Container>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} className={classes.border}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7bh-content"
                    id="panel7bh-header"
                  >
                    <Typography className={classes.heading}>Remove Members from Skill</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Container>
                        <lo>
                          <li>On the Members list, select the members for whom skill needs to be removed</li>
                          <li>Select Remove Skill</li>
                          <li>A drawer opens up with the list of skills and languages</li>
                          <li>Select the skill or language</li>
                          <li>Click Next to remove the skill from the selected members</li>
                        </lo>
                      </Container>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </TabPanel>
              <TabPanel value="2">
              <Accordion expanded={expanded === 'releaseNotesPanel4'} onChange={handleChange('releaseNotesPanel4')} className={classes.border}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="releaseNotesPanel4bh-content"
                    id="releaseNotesPanel4bh-header"
                  >
                    <Typography >December 12, 2022 - Version 3.1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Container>
                        <ul>
                          <li>Handle Pop up login for customers using SSO to login to Genesys Cloud</li>
                        </ul>
                      </Container>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion pt={3} expanded={expanded === 'releaseNotesPanel3'} onChange={handleChange('releaseNotesPanel3')} className={classes.border}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="releaseNotesPanel3bh-content"
                    id="releaseNotesPanel3bh-header"
                  >
                    <Typography >November 22, 2022 - Version 3.0</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Container>
                        <ul>
                          <li>Skill assignments will be applicable only to users who belong to "Bulk Skills Administration Users" group and not to all users of the Org</li>
                        </ul>
                      </Container>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'releaseNotespanel2'} onChange={handleChange('releaseNotespanel2')} className={classes.border}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="releaseNotespanel2bh-content"
                    id="releaseNotespanel2bh-header"
                  >
                    <Typography >October 10, 2022 - Version 2.0</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Container>
                        <ul>
                          <li>Group Assignment Feature</li>
                        </ul>
                      </Container>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'releaseNotespanel1'} onChange={handleChange('releaseNotespanel1')} className={classes.border}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="releaseNotespanel1bh-content"
                    id="releaseNotes-header"
                  >
                    <Typography >October 15, 2021 - Version 1.0</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Container>
                        <ul>
                          <li>Skill Management</li>
                        </ul>
                      </Container>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </TabPanel>
            </TabContext>
          </Box>

        </div>
      </Container>
    </div>
  )
}



export default HelpView