import React, { Component } from 'react';
import '../style.css';
import WizardApp from '../scripts/wizard-app';

let isAppInstalled = false;

class IndexView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            availableClass: "hidden",
            unavailableClass: "hidden",
            loadingClass: "",
            titleClass: "title hidden",
            userName: "",
            mainClass: "wiz-main hidden",
            progressBarClass: "wiz-progress-bar hidden",
            errorClass: "hidden",
            errorMessage: "Error"
        };
    }

    componentWillMount() {
        let bulkSkillsAdministrationApp = new WizardApp();
        bulkSkillsAdministrationApp.start()
            .then(() => {
                // Check if the product is installed either via PS/AF
                console.log('Check if the product is installed either via PS/AF')
                return bulkSkillsAdministrationApp.isExisting()
            })
            .then((isExisting) => {
                if (isExisting) {
                    // Product is installed and redirecting to application
                    console.log('Product is installed and redirecting to application')
                    isAppInstalled = true;
                    this.props.history.push('/');
                } else {
                    // Product not installed via PS and so checking for AF availability and display appropriate message
                    console.log('Check for product availability in AF');
                    bulkSkillsAdministrationApp.validateProductAvailability()
                        .then((isAvailable) => {
                            if (isAvailable) {
                                this.setState({ availableClass: "" });
                            } else {
                                this.setState({ unavailableClass: "" });
                            }
                        });
                    return Promise.resolve();
                }
            })
            .then(() => {
                // Get the user information to display on the screen
                if (!isAppInstalled)
                    return bulkSkillsAdministrationApp.getUserDetails();
            })
            .then((user) => {
                // Display welcome message after everything has been loaded

                if (!isAppInstalled) {
                    this.setState({ loadingClass: "hidden" });
                    this.setState({ titleClass: "title" });
                    this.setState({ progressBarClass: "wiz-progress-bar" });
                    this.setState({ mainClass: "wiz-main" });

                    if (user) {
                        this.setState({ userName: user.name });
                    }
                }

            })
            .catch((e) => {
                console.log('Error in index view: ' + e);
                this.setState({ loadingClass: "hidden" });
                this.setState({ mainClass: "wiz-main" });
                this.setState({ errorClass: "" });
                this.setState({ errorMessage: "Error in installation." });
            });
    };

    render() {
        return (
            <div className="full-height">
                <div id="background"></div>
                <noscript>
                    For full functionality of this site it is necessary to enable JavaScript. Here are the <a
                        href="http://www.enable-javascript.com/" target="_blank" rel="noopener noreferrer">instructions how to enable JavaScript in your web
            browser</a>.
                </noscript>

                <header>
                    <p>Bulk Skills Administration /&nbsp; Start</p>
                </header>

                <div className="wiz-content">
                    <div id="loading" className={this.state.loadingClass} style={{ marginTop: "30px", textAlign: "center" }}>
                        <i className="fa fa-spinner fa-spin" style={{ fontSize: "70px" }}></i>
                    </div>

                    <div className={this.state.titleClass} >
                        <span className="txt-greeting">Hi</span>
                        <span id="username"> {this.state.userName}</span>!
            <div className="txt-greeting-2">
                            Welcome to the Bulk Skills Administration Application
            </div>
                    </div>

                    <ul className={this.state.progressBarClass}>
                        <li className="active current">
                            <span className="txt-start">Start</span>
                        </li>
                        <li className="">
                            <span className="txt-install">Install</span>
                        </li>
                        <li className="">
                            <span className="txt-summary">Summary</span>
                        </li>
                    </ul>

                    <main className={this.state.mainClass}>
                        <div id="available" className={this.state.availableClass}>
                            <p className="success">
                                <span className="txt-product-available">Product Available</span> <i
                                    className="fas fa-check-circle enlarge"></i>
                            </p>
                            <p>
                                <span className="txt-available-message">We're ready to set you up. Please click the Start button.</span>
                            </p>
                            <button id="next" onClick={() => this.props.history.push('/install')} className="wiz-btn-info right">
                                <span className="txt-start">Start</span> <i className="button-icon fas fa-chevron-right"></i></button>
                        </div>

                        <div id="unavailable" className={this.state.unavailableClass}>
                            <p className="fail">
                                <span className="txt-product-not-available">Product not available</span> <i
                                    className="fas fa-times-circle enlarge"></i>
                            </p>
                            <p>
                                <span className="txt-not-available-message">
                                    We're sorry but your Genesys Cloud org does not have the Bulk Skills Administration app enabled.
                                    Please contact the authorities.
                    </span>
                            </p>
                        </div>

                        <div id="error" className={this.state.errorClass}>
                            <p className="fail">
                                <span className="txt-product-error">Product could not be installed</span> <i
                                    className="fas fa-exclamation-circle enlarge"></i>
                            </p>
                            <p>
                                <span id="errormessage" className="txt-error-message">
                                    {this.state.errorMessage}
                                </span>
                            </p>
                        </div>

                    </main>
                </div>
                <footer></footer>
            </div>
        )
    }
}

export default IndexView;