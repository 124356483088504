import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getOrganization, getMe } from '../services/purecloud'
import { validateOrg } from '../services/backend'
import config from '../../src/config'

const PrivateRoute = ({ component: Component, ...rest }) => {
  async function checkAccess() {
    try {
      if (sessionStorage.getItem('purecloud-csp-token')) {
        const customerConfig = await validateOrg(sessionStorage.getItem('purecloud-csp-token'), sessionStorage.getItem('purecloud-csp-env'))
        console.log('customerVersion:', customerConfig.customerVersion)

        const user = await getMe(sessionStorage.getItem('purecloud-csp-token'), sessionStorage.getItem('purecloud-csp-env'))

        let hasAFPermission = false

        if (customerConfig.customerVersion === "AF") {
            hasAFPermission = user.authorization.permissionPolicies.find(p => p.domain === 'integration' && p.entityName === config.permissionPolicyEntityName) ? true : false
        
        } else if (customerConfig.customerVersion === "PS") {
            hasAFPermission = user.authorization.roles.find(r => r.name === config.provisioningInfo.roles[0].name) ? true : false
        }

        return hasAFPermission
      }
      else
        return false;
    } catch (error) {
      console.log(error)
      return false;
    }
  }

  return (
    <Route {...rest} render={(props) => (
      sessionStorage.getItem('purecloud-csp-token') ? checkAccess() ? <Component {...props} /> : <Redirect to='/unauthorized' /> : <Redirect to='/login' />
    )} />
  )
}

export default PrivateRoute