import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import purecloud from '../../../services/purecloud'
import utils from '../../../services/utils'
import ProgressBarModal from '../../modals/progressBarModal/ProgressBarModal'
import backendAPI from '../../../services/backend';
import WaitingModal from '../../modals/waitingModal/WaitingModal'
import AlertModal from '../../modals/alertModal/AlertModal'


const SkillDetailModal = (props) => {
    const token = sessionStorage.getItem('purecloud-csp-token')
    const env = sessionStorage.getItem('purecloud-csp-env')
    const { open, handleClose, modalTitle, button1, button2, entityType, entityID, selectedMembers } = props
    const [value, setValue] = useState(0);
    const [membersToDelete, setMembersToDelete] = useState([]);
    const [progressBarMax, setProgressBarMax] = useState(0)
    const [progressBarCurrent, setProgressBarCurrent] = useState(0)
    const [progressBarHeaderText, setProgressBarHeaderText] = useState()
    const [workInProgress, setWorkInProgress] = useState(false)
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [waitingModalHeader, setWaitingModalHeader] = useState('')
    const [error, setError] = useState()
    const deleteSkillsFromUser = async () => {
        //TO remove selected users from skills
        console.log("Members to be removed: " + selectedMembers)
        for (let i = 0; i < selectedMembers.length; i++) {
            membersToDelete.push({ userId: selectedMembers[i] })
        }
        handleClose()
        setWorkInProgress(true)
        setProgressBarHeaderText('Removing selected users...')
        // format GC api call
        let bulkUpdate
        console.log('entityType:', entityType)
        switch (entityType.toLowerCase()) {
            case 'skill': bulkUpdate = membersToDelete.map(x => ({ userId: x.userId, skillId: entityID }))
                break;
            case 'language': bulkUpdate = membersToDelete.map(x => ({ userId: x.userId, languageId: entityID }))
                break;
            default:
                break;
        }

        // delete skill from users + retries
        if (bulkUpdate) {
          //  let executionError = false
            let nTotalUpdates = bulkUpdate.length
            setProgressBarMax(nTotalUpdates)
            let nUpdates = 0, response
            while (nUpdates < nTotalUpdates) {
                switch (entityType.toLowerCase()) {
                    case 'skill': response = await purecloud.bulkRemoveSkillFromUser(token, env, bulkUpdate)
                        break;
                    case 'language': response = await purecloud.bulkRemoveLanguageFromUser(token, env, bulkUpdate)
                        break;
                    default:
                        break;
                }
                nUpdates += response.nResolvedPromises
                setProgressBarCurrent(nUpdates)
                if (response.rejectedPromiseResponse.length > 0) {
                    bulkUpdate = response.rejectedPromiseResponse.map(x => x.retry)
                    if (response.retryAfterMs) {
                     //   console.log("Sleeping for",response.retryAfterMs,"seconds")
                        await utils.sleep(response.retryAfterMs)
                    }
                //     else {
                //     executionError = true
                //     break;
                // }

                }
            }
            // if (executionError)
            //     setProgressBarHeaderText('An Error Occured!')
            // else
                setProgressBarHeaderText('Complete!')
            await utils.sleep(2000)
            setProgressBarCurrent(0)
            setProgressBarMax(0)
            setMembersToDelete([])
            setWorkInProgress(false)
        }
        //let i = true;
        // while(i)
        // {
        try {
            setIsWaitingModalOpen(true)
            setWaitingModalHeader("Loading Members")
            const responses = await backendAPI.getUsers(token, env)
            sessionStorage.setItem('EA-BSA-users', JSON.stringify(responses.users))
            window.location.reload()
           // i=false;
        }
        catch (error) {
            setError(`An error occured while retrieving Users:${JSON.stringify(error.message)}`)
            console.log('error:', error)
           // await utils.sleep(error.match(/\[(.*?)\]/)[1])

        }
        finally {
            setIsWaitingModalOpen(false)
        }
   // }


    }

    return (
        <div id="modal-container">
            <WaitingModal
                isOpen={isWaitingModalOpen}
                header={waitingModalHeader}
            />
            <ProgressBarModal
                isOpen={workInProgress}
                header={progressBarHeaderText}
                progress={progressBarCurrent}
                min={0}
                max={progressBarMax}
            />
            <AlertModal
                isOpen={!!error}
                header='Error'
                toggle={() => { setError(undefined) }}
                body={error}
            />
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <MuiDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <button onClick={handleClose} style={{ float: "right" }}> <Close /></button>
                    <span>{modalTitle} </span>
                </MuiDialogTitle>
                <MuiDialogActions>
                    <div >
                        <Button variant="contained" color = 'primary' style={{ textTransform: 'none' }} onClick={deleteSkillsFromUser}>{button1}</Button>
                    </div>
                    <div >
                        <Button variant="contained" color="white" style={{ textTransform: 'none' }} onClick={handleClose}>{button2}</Button>
                    </div>
                </MuiDialogActions>
            </Dialog>
        </div>
    )

}
export default SkillDetailModal;