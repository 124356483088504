import React, { Fragment, useState } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from "@material-ui/styles"
import './GroupsSelector.css'

let filtersBy = []

const GroupsSelector = (props) => {
    const { allgroups, staticgroups, dynamicgroups, selectedFiltersChanged, styles, dynamicgroupsEnabled } = props
    if(dynamicgroupsEnabled)
    {
        filtersBy = [
            { id: 'allgroups', label: 'All Groups' },
            { id: 'staticgroups', label: 'Static Groups' },
            { id: 'dynamicgroups', label: 'Dynamic Groups' }
        ]
    }
    else
    {
        filtersBy = [
            { id: 'staticgroups', label: 'Static Groups' },
        ]
    }
    const [filterByValue, setFilterByValue] = useState(filtersBy[0])
    const [filters, setFilters] = useState([])
    const [dataSource, setDataSource] = useState(allgroups)
    const useStyles = makeStyles(styles)
    const classes = useStyles();
   
    const filterByOnChange = (event, value) => {
        setFilterByValue(value)
        setFilters([])
        selectedFiltersChanged('all',[])
        const filterId = filtersBy.find(x => x.id === value.id).id
        switch (filterId) {
            case 'allgroups': setDataSource(allgroups)
                break;
            case 'staticgroups': setDataSource(staticgroups)
                break;
            case 'dynamicgroups': setDataSource(dynamicgroups)
                break;
            default: setDataSource(allgroups)
                break;
        }
    }

    const selectedFiltersOnChange = (event, value) => {
        setFilters(value)
        selectedFiltersChanged(filterByValue.id, value)
    }

    return (
        <Fragment>
            <Autocomplete className={classes.root}
                id="combo-box-filters"
                value={filterByValue}
                onChange={(event, newValue) => { filterByOnChange(event, newValue) }}
                options={filtersBy}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField {...params} label="Filter By" variant="outlined" size="small" />}
            />
            <Autocomplete className={classes.root}
                multiple
                limitTags={4}
                id="combo-box-entities-search"
                value={filters}
                onChange={(event, newValue) => { selectedFiltersOnChange(event, newValue) }}
                options={dataSource}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Search" variant="outlined" size="small"/>}
            />
        </Fragment>
    )
}

export default GroupsSelector