import React, { Fragment, useEffect, useState } from 'react'
import { Button, Checkbox, Drawer, useScrollTrigger } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, FormControl, MenuItem, Select } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from "@material-ui/styles";
import Radio from '@mui/material/Radio';
import purecloud from '../../services/purecloud'
import ConfirmationModal from '../modals/confirmationModal/ConfirmationModal'
import utils from '../../services/utils'
import ProgressBarModal from '../modals/progressBarModal/ProgressBarModal'
import backendAPI from '../../services/backend';
import WaitingModal from '../modals/waitingModal/WaitingModal'
import AlertModal from '../modals/alertModal/AlertModal'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import GroupsSelector from '../groupsSelector/GroupsSelector'
import SelectedGroupMembersTable from '../table/selectedGroupMembersTable'
import './AddSkillsDrawer.css'
import { TextsmsTwoTone } from '@material-ui/icons';


const AddSkillsDrawer = (props) => {
    const token = sessionStorage.getItem('purecloud-csp-token')
    const env = sessionStorage.getItem('purecloud-csp-env')
    const { open, toggle, anchor, selectedAgents, allSkillsLanguages, toggleCloseDrawer, modifiedAgentSkills, groupsUsers, enableFinishButton } = props
    const [pageSize, setPageSize] = useState(25)
    const [type, setType] = useState('All Selected')
    const [filteredData, setfilteredData] = useState(allSkillsLanguages)
    const [sortedEntities, setSortedEntities] = useState(allSkillsLanguages)
    const [filterData, setfilterData] = useState(allSkillsLanguages)
    const [allEntities, setAllEntitites] = useState(allSkillsLanguages)
    const [filtertext, setFilterText] = useState('')
    const [selectedSkilledUsers, setSelectedSkilledUsers] = useState([])
    const [allSelectedSkilledUsers, setAllSelectedSkilledUsers] = useState([])
    const [editedRatingUsers, setEditedRatingUsers] = useState([])
    const [selectedUsersforSkillEdit, setSelectedUsersforSkillEdit] = useState([])
    const [message, setMessage] = useState()
    const [data, setData] = useState({ undefined })
    const [allUsers, setAllUsers] = useState()
    const [selectedUsers, setSelectedUsers] = useState([])
    const [hiddenUpdateRating, setHiddenUpdateRating] = useState(true)
    const [numberOfSelectedUsers, setNumberOfSelectedUsers] = useState([])
    const [selectedGroups, setSelectedGroups] = useState([])
    const [disableAdd, setDisableAdd] = useState(true)
    const [selectedSkill, setSelectedSkill] = useState([])
    const [progressBarCurrent, setProgressBarCurrent] = useState(0)
    const [progressBarMax, setProgressBarMax] = useState(0)
    const [value, setValue] = useState(0)
    const [hiddenButtons, setHiddenButtons] = useState(true)
    const [skillSelected, setSkillSelected] = useState(true)
    const [sameRatingEnabled, setSameRatingEnabled] = useState(false)
    const [progressBarHeaderText, setProgressBarHeaderText] = useState()
    const [disableRating, setDisableRating] = useState(true)
    const [checkBoxSet, setCheckBoxSet] = useState(false)
    const [workInProgress, setWorkInProgress] = useState(false)
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [waitingModalHeader, setWaitingModalHeader] = useState('')
    const [error, setError] = useState()
    const styles = {
        root: {
            display: "flex",
            flexDirection: 'column',
        }
    }

    const useStyles = makeStyles(styles)
    const classes = useStyles();
    let radioChecked = [];
    const [nextButtonClicked, setNextButtonClicked] = useState(false)
    const [selectionModel, setSelectionModel] = React.useState(radioChecked);
    radioChecked = selectionModel;
    const selectedRow = filteredData.filter((item) => {
        return item.id === selectionModel[0];
    });

    let selectedMembers = []

    useEffect(() => { // initial default filter on all users for that skill
        try {
            //Arrange common skills first
            let selectedUsersSkillLanguages = []; let selectedUsersSkillLanguagesId = []; let commonEntities = [];

            for (let i = 0; i < selectedAgents.length; i++) {
                //parse users to find the common skills and assign to commonSkills
                let users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
                selectedUsersSkillLanguages = [...selectedUsersSkillLanguages, ...users
                    .find(user => user.id === selectedAgents[i]).skills, ...users
                        .find(user => user.id === selectedAgents[i]).languages]
            }
            

            // setSelectedMembersEntities(selectedUsersSkillLanguages)
            for (let i = 0; i < selectedUsersSkillLanguages.length; i++)
                selectedUsersSkillLanguagesId = [...selectedUsersSkillLanguagesId, selectedUsersSkillLanguages[i].id]

            let unique = new Set(selectedUsersSkillLanguagesId)
            
            let selectedMembersEntities = []
            for (const element of unique)
                selectedMembersEntities = [...selectedMembersEntities, ...allEntities.filter(x => x.id === element)]
            

            if (selectedAgents.length === 1)
                commonEntities = [...commonEntities, ...selectedUsersSkillLanguagesId]
            else {
                for (let i = 0; i < selectedUsersSkillLanguagesId.length; i++) {
                    for (let j = i + 1; j < selectedUsersSkillLanguagesId.length; j++) {
                        if (selectedUsersSkillLanguagesId[i] === selectedUsersSkillLanguagesId[j])
                            commonEntities = [...commonEntities, selectedUsersSkillLanguagesId[i]]
                    }
                }
            }
            

            //common skills first
            let sort = []
            for (let i = 0; i < commonEntities.length; i++) {
                sort = [...sort, ...allEntities.filter(skill => skill.id === commonEntities[i])]
            }

            let all = allEntities
            //delete commonskills in filterdata and append to the beginning
            for (let i = 0; i < all.length; i++) {
                for (let j = 0; j < selectedMembersEntities.length; j++) {

                    if (all[i].id === selectedMembersEntities[j].id) {
                        all = all.filter((item) => item.id !== all[i].id);
                    }

                }
            }
            // sort selectedMembersEntities alphabetically and skill first, language second
            //get selectedMembersEntities skills and sort
            let selectedMembersSkills = selectedMembersEntities.filter(x => x.type === "skill")
            selectedMembersSkills = selectedMembersSkills.sort(function (a, b) {
                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            })
            //get selectedMembersEntities languages and sort
            let selectedMembersLanguages = selectedMembersEntities.filter(x => x.type === "language")
            selectedMembersLanguages = selectedMembersLanguages.sort(function (a, b) {
                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            })
            //merge
            all = [...selectedMembersSkills, ...selectedMembersLanguages, ...all]
            all = removeDuplicates(all)
            console.info('AddSkillDrawer: Sorted entities, length:', all, all.length)
            //setAllEntitites(all)
            
            /**************************************************************************************/

            setfilteredData(all)
            setSortedEntities(all)
        } catch (error) {
            console.error('error:', error)
        }
    }, [allSkillsLanguages])
 

    const removeDuplicates = (array) => {
        let jsonObject = array.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return (uniqueArray);
    }

    const RatingEditInputCell = (props) => {
        const { id, value, api, field } = props;
    
        const handleChange = (event) => {
            api.setEditCellValue(
                { id, field, value: Number(event.target.value) },
                event
            );
            // Check if the event is not from the keyboard
            // https://github.com/facebook/react/issues/7407
            if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
                api.commitCellChange({ id, field });
                api.setCellMode(id, field, "view");
            }
            //   setHiddenButtons(false);
            if (JSON.stringify(editedRatingUsers).includes(id)) {
                editedRatingUsers.forEach(element => {
                    if (element.userId === id)
                        element.value = Number(event.target.value)
                })
            }
            else
                setEditedRatingUsers([...editedRatingUsers, { userId: id, value: Number(event.target.value) }])
        };


        return (
            <div className={classes.root}>
                <Rating
                    name="rating"
                    precision={1}
                    value={Number(value)}
                    onChange={handleChange}
                    size={'large'}
                    style={{ color: "#444a52" }}
                />
            </div>
        );
    }
    const tableRowSelected = (params) => {
        //after selecting common rating, if user removes any selected agent
        if(value > 0)
        {
            if(params.length<selectedUsersforSkillEdit.length)
        {
            let difference = selectedUsersforSkillEdit.filter(x => !params.includes(x));
            console.debug("AddSKillsDrawer: Difference:", difference, value)
            for(let i=0;i<difference.length;i++)
            {
                if(editedRatingUsers.some(x=>x.userId === difference[i]) )
                {
                    if(editedRatingUsers.find(x=>x.userId === difference[i]).value === value)
                         editedRatingUsers.splice(editedRatingUsers.findIndex(a => a.userId === difference[i]) , 1)
                } 
            }
            
               
        }
        //after selecting common rating, if user selects any extra agent
        if(params.length>selectedUsersforSkillEdit.length)
        {
            let difference = params.filter(x => !selectedUsersforSkillEdit.includes(x));
            console.debug("AddSKillsDrawer: Difference:", difference, value)
            editedRatingUsers.push({userId:difference[0], value:value})               
        }
    }
        setSelectedUsersforSkillEdit(params)
        console.info("AddSKillsDrawer: SelectedRow", params, "SelectedUsers", selectedUsersforSkillEdit)
        console.info("AddSKillsDrawer: Edited Users", editedRatingUsers)
        if (params.length > 0) {
            setHiddenUpdateRating(false)
        }
        else {
            setHiddenUpdateRating(true)
            setValue(0)
            setSameRatingEnabled(false)
            setCheckBoxSet(false)
        }
        if (sameRatingEnabled === true)
            setDisableRating(false)
        else
            setDisableRating(true)
    }

    const columns = [
        {
            field: "",
            headerName: "",
            width: 50,
            sortable: false,
            renderCell: (params) => (
                <Radio checked={radioChecked[0] === params.id} value={params.id} />
            )
        },
        {
            field: "name",
            headerName: "Name",
            sortable: false,
            resizable: false,
            minWidth: 100,
            flex: 0.65,
            type: 'string',
            headerClassName: 'super-app-theme--header'

        },
        {
            field: "type",
            headerName: "Type",
            sortable: false,
            resizable: false,
            minWidth: 100,
            flex: 0.35,
            type: 'string',
            headerClassName: 'super-app-theme--header'

        },
    ];



    const columnsUsers = [
        {
            field: "name",
            headerName: "Name",
            sortable: false,
            resizable: false,
            minWidth: 100,
            flex: 0.65,
            type: 'string',
            headerClassName: 'super-app-theme--header'

        },
        {
            field: "proficiency",
            headerName: "Rating",
            sortable: false,
            resizable: false,
            minWidth: 100,
            flex: 0.5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div className={classes.root}>
                    <Rating readOnly value={params.value} size={'large'} style={{ color: "#444a52" }} />
                </div>),
            renderEditCell: RatingEditInputCell,
            editable: true
        },
    ];

    const handleCloseDrawer = () => {
        setSelectionModel([])
        setNextButtonClicked(false)
        toggleCloseDrawer(false)
        setType('All Selected')
        setfilteredData(sortedEntities)
        setSkillSelected(true)
    }

    const selectRatingForSkills = () => {
        
        setNextButtonClicked(true)
        let users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
        let skillUsers = []
        for (let i = 0; i < selectedAgents.length; i++) {
            skillUsers = [...skillUsers, users.find(x => x.id === selectedAgents[i])]
        }

        for (let i = 0; i < skillUsers.length; i++) {
            for (let j = 0; j < skillUsers[i].skills.length; j++) {
                if (skillUsers[i].skills[j].id === selectionModel[0])
                    skillUsers[i].proficiency = skillUsers[i].skills[j].ratings
            }
            for (let j = 0; j < skillUsers[i].languages.length; j++) {
                if (skillUsers[i].languages[j].id === selectionModel[0])
                    skillUsers[i].proficiency = skillUsers[i].languages[j].ratings
            }
        }
        console.log("AddSKillsDrawer: AssignedMembers:", selectedAgents, skillUsers)
        setSelectedSkilledUsers(skillUsers)
        setAllSelectedSkilledUsers(skillUsers)
    }

    const assignSkills = async () => {
        setSkillSelected(true)
        setMessage()
        console.log("AddSKillsDrawer.AssignSkills: Skill:", selectedRow[0], "Users: ", editedRatingUsers)
        setWorkInProgress(true)
        setProgressBarHeaderText('Updating ratings...')

        let bulkUpdate
        if (selectedRow[0].type === 'skill')
            bulkUpdate = editedRatingUsers.map(x => ({ userId: x.userId, proficiency: x.value, skillId: selectedRow[0].id }))
        if (selectedRow[0].type === 'language')
            bulkUpdate = editedRatingUsers.map(x => ({ userId: x.userId, proficiency: x.value, languageId: selectedRow[0].id }))
        

        if (bulkUpdate) {
            //   let executionError = false
            let nTotalUpdates = bulkUpdate.length
            setProgressBarMax(nTotalUpdates)
            let nUpdates = 0, response
            while (nUpdates < nTotalUpdates) {
                if (selectedRow[0].type === 'skill')
                    response = await purecloud.bulkAddOrUpdateUserSkill(token, env, bulkUpdate)
                if (selectedRow[0].type === 'language')
                    response = await purecloud.bulkAddOrUpdateUserLanguage(token, env, bulkUpdate)
                nUpdates += response.nResolvedPromises
                setProgressBarCurrent(nUpdates)
                if (response.rejectedPromiseResponse.length > 0) {
                    bulkUpdate = response.rejectedPromiseResponse.map(x => x.retry)
                    if (response.retryAfterMs) {
                        console.log("Sleeping for", response.retryAfterMs, "seconds")
                        await utils.sleep(response.retryAfterMs)
                    }

                }
            }
            setProgressBarHeaderText(`${editedRatingUsers.length} members successfully updated! Click Finish when skill modifications are complete`)
            await utils.sleep(2000)
            setProgressBarCurrent(0)
            setProgressBarMax(0)
            setEditedRatingUsers([])
            setWorkInProgress(false)
            setNextButtonClicked(false)
            setSelectionModel([])
            setValue(0)
            setSameRatingEnabled(false)
            setCheckBoxSet(false)
            setHiddenUpdateRating(true)


        }
        try {
            const promises = [
                backendAPI.getUsers(token, env),
                backendAPI.getDynamicGroups(token, env)
            ]
            setIsWaitingModalOpen(true)
            setWaitingModalHeader("Loading Members")
            const responses = await Promise.all(promises)
            for (const response of responses) {
                switch (response.id) {
                    case 'users': sessionStorage.setItem('EA-BSA-users', JSON.stringify(response.users))
                        break;
                    case 'dynamicgroups': sessionStorage.setItem('EA-BSA-dynamicgroups', JSON.stringify(response.dynamicGroups))
                        break;
                    default:
                        break;
                }
            }
            let users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
            let groupsMembers = JSON.parse(sessionStorage.getItem('EA-BSA-SelectedGroupMembers'))
            for (let i = 0; i < groupsMembers.length; i++) {
                for (let j = 0; j < users.length; j++) {
                    if (groupsMembers[i].id === users[j].id) {
                        users[j].selectedGroups = groupsMembers[i].selectedGroups
                    }
                }
            }

            let usersAfterSkillChange = new Array()
            for (let i = 0; i < users.length; i++) {
                for (let j = 0; j < groupsUsers.length; j++) {
                    if (groupsUsers[j].id === users[i].id) {
                        usersAfterSkillChange.push(users[i])
                    }
                }
            }
            let sortUsersAfter = new Array()
            for(let i=0;i<groupsMembers.length;i++)
            {
                sortUsersAfter.push(usersAfterSkillChange.find(x=>x.id===groupsMembers[i].id))
            }
            //console.log("AddSkillsDrawer: Modified:",usersAfterSkillChange,"Actual: ", groupsMembers, "final: ", sortUsersAfter)
            modifiedAgentSkills([])
            modifiedAgentSkills(sortUsersAfter)
            setIsWaitingModalOpen(false)
        }
        catch (error) {
            setError(`An error occured while retrieving Users:${JSON.stringify(error.message)}`)
            console.error('error:', error)
        }
        finally {
            setIsWaitingModalOpen(false)
        }
        toggleCloseDrawer(false)
        enableFinishButton(true)

    }
    const cancelAssignSkills = async () => {
        setMessage()
        setValue(0)
        setDisableRating(true)
        setCheckBoxSet(false)
        setEditedRatingUsers([])
        setSameRatingEnabled(false)
    }
    const handleSameRating = (event) => {
        
        setSameRatingEnabled(event.target.checked)
        if (event.target.checked === true) {
            setCheckBoxSet(true)
            setDisableRating(false)
        }
        else {
            setCheckBoxSet(false)
            setDisableRating(true)
            setValue(0)
            //remove selected members from editedskillratings
            for(let i=0;i<selectedUsersforSkillEdit.length;i++)
            {
                editedRatingUsers.splice(editedRatingUsers.find(x=>x.userId === selectRatingForSkills[i]),1)
            }
        }
    }


    return (
        <Drawer open={open} anchor={anchor} onClose={toggle(false)}>
            <ConfirmationModal
                isOpen={!!message}
                header='Update Skill'
                body={message}
                button1={assignSkills}
                button2={cancelAssignSkills}
                button1Text='Save'
                button2Text='Discard'
            />
            <ProgressBarModal
                isOpen={workInProgress}
                header={progressBarHeaderText}
                progress={progressBarCurrent}
                min={0}
                max={progressBarMax}
            />
            <WaitingModal
                isOpen={isWaitingModalOpen}
                header={waitingModalHeader}
            />
            <AlertModal
                isOpen={!!error}
                header='Error'
                toggle={() => { setError(undefined) }}
                body={error}
            />

            <Fragment>
                <h5 style={{ marginLeft: 5 }}>Assign Skills and Languages</h5>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDrawer}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {nextButtonClicked === false &&
                    <Fragment>
                        <Grid container spacing={1} style={{
                            margin: 0,
                            width: '100%'
                        }}>
                            <Grid item >
                                <FormControl variant="outlined" >
                                    <TextField variant="outlined" size="small" style={{ minWidth: 150, width: "80%" }}
                                        value={type}
                                        select={true}
                                        SelectProps={{
                                            MenuProps: {
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }
                                        }}
                                        onChange={e => {
                                            setType(e.target.value)
                                            setfilteredData(sortedEntities.filter(x => (x.type === e.target.value.toLowerCase()) && (x.name.toLowerCase().includes(filtertext.toLowerCase()))))
                                            if (e.target.value === "All Selected") {
                                                setfilteredData(sortedEntities.filter(x => x.name.toLowerCase().includes(filtertext.toLowerCase())))
                                            }
                                        }}>
                                        <MenuItem value="All Selected" >All Selected</MenuItem>
                                        <MenuItem value="Skill">Skill</MenuItem>
                                        <MenuItem value="Language">Language</MenuItem>
                                    </TextField>
                                </FormControl>
                            </Grid>

                            <Grid item  >
                                <TextField style={{ width: "120%" }}

                                    InputProps={{
                                        endAdornment: (
                                            <SearchIcon style={{ color: "#e0e0e0" }} />
                                        )
                                    }}
                                    label="Search"
                                    defaultValue=""
                                    variant="outlined"
                                    size="small"
                                    onChange={e => {
                                        setFilterText(e.target.value)
                                        if (type !== "All Selected")
                                            setfilteredData(sortedEntities.filter(x => (x.name.toLowerCase().includes(e.target.value.toLowerCase())) && (x.type === type.toLowerCase())))
                                        else
                                            setfilteredData(sortedEntities.filter(x => (x.name.toLowerCase().includes(e.target.value.toLowerCase()))))
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {/* Add datagrid */}
                        <DataGrid
                            rows={filteredData}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[25, 50, 75, 100]}
                            pagination
                            disableColumnFilter
                            disableColumnMenu
                            hideFooterSelectedRowCount
                            showColumnRightBorder
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            selectionModel={selectionModel}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                                setSkillSelected(false)
                            }}
                        //onCellClick={currentlySelected}
                        />
                        <div>
                            <Button variant="contained" color='primary' disabled={skillSelected} style={{ textTransform: 'none', float: 'left', margin: 5 }} onClick={selectRatingForSkills}>Next</Button>
                            <Button variant="contained" style={{ textTransform: 'none', float: 'left', margin: 5 }} onClick={handleCloseDrawer}>Cancel</Button>
                        </div>
                    </Fragment>
                }
                {nextButtonClicked === true &&
                    <Fragment>
                        <TextField style={{ width: "70%", marginLeft: 5, marginBottom: 10 }}

                            InputProps={{
                                endAdornment: (
                                    <SearchIcon style={{ color: "#e0e0e0" }} />
                                )
                            }}
                            label="Search"
                            defaultValue=""
                            variant="outlined"
                            size="small"
                            onChange={e => {
                                setFilterText(e.target.value)
                                setSelectedSkilledUsers(allSelectedSkilledUsers.filter(x => (x.name.toLowerCase().includes(e.target.value.toLowerCase()))))
                            }}
                        />
                        <DataGrid
                            rows={selectedSkilledUsers}
                            columns={columnsUsers}
                            pageSize={pageSize}
                            rowsPerPageOptions={[25, 50, 75, 100]}
                            pagination
                            disableColumnFilter
                            disableColumnMenu
                            hideFooterSelectedRowCount
                            showColumnRightBorder
                            checkboxSelection
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            onSelectionModelChange={tableRowSelected}
                        //onCellClick={currentlySelected}
                        />
                        <div style={{ backgroundColor: "#ebf2f8" }}>
                            {!hiddenUpdateRating &&
                                <Fragment>
                                    <Checkbox checked={checkBoxSet} onChange={handleSameRating} color="primary"></Checkbox>
                                    <label> {`Apply the same rating for the ${selectedUsersforSkillEdit.length} selected members`}</label><br />
                                    <Rating
                                        name="simple-controlled"
                                        value={value}
                                        disabled={disableRating}
                                        onChange={(event, newValue) => {
                                            /*Fix start for SA-49*/
                                            console.log('skillDetailsTable.newValue', newValue)
                                            if (newValue == null) {
                                                setValue(0)
                                                setEditedRatingUsers([])
                                                setHiddenButtons(true)
                                            }
                                            /*Fix end for SA-49*/
                                            else {
                                                setValue(newValue);
                                                setHiddenButtons(false)
                                                console.log(selectedUsersforSkillEdit)
                                                for (let i = 0; i < selectedUsersforSkillEdit.length; i++) {
                                                    if (JSON.stringify(editedRatingUsers).includes(selectedUsersforSkillEdit[i])) {
                                                        editedRatingUsers.forEach(element => {
                                                            if (element.userId === selectedUsersforSkillEdit[i])
                                                                element.value = Number(newValue)
                                                        })
                                                    }
                                                    else
                                                        editedRatingUsers.push({ userId: selectedUsersforSkillEdit[i], value: Number(newValue) })

                                                }
                                                console.log(editedRatingUsers)
                                            }

                                        }} style={{ color: "#444a52" }}
                                    /><br />


                                </Fragment>
                            }
                        </div>
                    
                        <div>
                            <Button disabled={!editedRatingUsers.length>0} variant="contained" color='primary' style={{ textTransform: "none", margin: 5 }} onClick={() => { setMessage("Do you want to update ratings for " + editedRatingUsers.length + " members?"); console.log("EditedRatingUsers: ", editedRatingUsers) }}> Assign</Button>
                            <Button variant="contained" style={{ textTransform: "none", margin: 5 }} onClick={() => { setNextButtonClicked(false); setEditedRatingUsers([]); setValue(0); setHiddenUpdateRating(true); setCheckBoxSet(false)}} > Cancel</Button>
                        </div>
                    </Fragment>
                }
            </Fragment>


        </Drawer>
    )
}

export default AddSkillsDrawer