import React from 'react';
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink

} from 'reactstrap'
import './Header.css'
import IconButton from '@material-ui/core/IconButton';
import HelpRounded from '@material-ui/icons/HelpRounded';

const Header = (props) => {
    return (
        <div>
            <Navbar dark expand="md" className="header">
                <Nav className="container-fluid" navbar>
                    <NavItem>
                        <NavbarBrand href="/">Bulk Skills Administration</NavbarBrand>
                    </NavItem>
                    <NavItem>
                        Version 3.1
                        <IconButton aria-label="help" href="\help">
                            <HelpRounded style={{ color: "black" }} />
                        </IconButton>
                    </NavItem>
                </Nav>
            </Navbar>
        </div>
    );
}

export default Header;