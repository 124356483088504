import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useLocation } from 'react-router-dom'
import Header from '../../header/Header';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';
import SkillDetailsTable from '../../table/skillDetailsTable'
import AddMembersDrawer from '../../addMembersDrawer/AddMembersDrawer';
import { FormControl } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Popover from '@material-ui/core/Popover';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import FilterSelector from '../../filtersSelector/FiltersSelector'
import SkillDetailModal from '../../modals/skillDetailModal/SkillDetailModal'
import Rating from '@material-ui/lab/Rating';
import './SkillDetailsPage.css'
import DeleteIcon from '@material-ui/icons/Delete';

const SkillDetailsPage = () => {
  const location = useLocation()
  // Drawer
  const [openDrawer, setOpenDrawer] = useState(false)
  const toggleDrawer = (open) => event => {

    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open)
  }
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [entityUsers, setEntityUsers] = useState([]);
  let entityID = location.state.entityID;
  let entityType = location.state.entityType;
  const id = undefined;
  const [anchorEl, setAnchorEl] = useState(null);
  const openPop = Boolean(anchorEl);
  const [filteredValues, setFilteredValues] = useState([]);
  const [oneCheckbox, setOneCheckbox] = useState(false);
  const [twoCheckbox, setTwoCheckbox] = useState(false);
  const [threeCheckbox, setThreeCheckbox] = useState(false);
  const [fourCheckbox, setFourCheckbox] = useState(false);
  const [fiveCheckbox, setFiveCheckbox] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const [modalTitle, setModaltitle] = useState('')
  const [button1, setButton1] = useState('')
  const [button2, setButton2] = useState('')
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [selectedUsers, setSelectedUsers] = useState([])
  const [numberOfSelectedUsers, setNumberOfSelectedUsers] = useState([])
  const [disableActionsButton, setDisableActionsButton] = useState(true)
  const [disableDrawerButton, setDisableDrawerButton] = useState(false)
  const [pendingMembers, setPendingMembers] = useState([])
  const [tempDivisions, setTempDivisions] = useState([])
  const [tempRoles, setTempRoles] = useState([])
  const [tempGroups, setTempGroups] = useState([])
  const [tempLocations, setTempLocations] = useState([])
  const [tempReportsTo, setTempReportsTo] = useState([])
  let filteredDivisions, filteredRoles, filteredGroupIDs, filteredLocationIDs, filteredReportsToIDs = []
  let filteredGroups = new Array(); let filteredLocations = new Array(); let filteredReportsTo = new Array();
  const [filterBy, setFilterBy] = useState()
  const [filterData, setFilterData] = useState([])

  useEffect(() => {
    const fetchUserData = () => {
      let users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
      switch (entityType.toLowerCase()) {
        case 'skill':
          users = users.filter(x => {
            const skill = x.skills.find(skill => skill.id === entityID)
            if (skill) return true
          }).map(user => ({
            ...user,
            division: user.divisions[0].name,
            proficiency: user.skills.find(x => x.id === entityID).ratings
          }))
          break;
        case 'language':
          users = users.filter(x => {
            const language = x.languages.find(language => language.id === entityID)
            if (language) return true
          }).map(user => ({
            ...user,
            division: user.divisions[0].name,
            proficiency: user.languages.find(x => x.id === entityID).ratings
          }))
          break;
        default:
          break;
      }
      setEntityUsers(users)
      setFilteredUsers(users)
      setPendingMembers(users)
    }
    fetchUserData();
  }, [])
  console.log('filteredUsers:', filteredUsers)

  const removeDuplicates = (array) => {
    let jsonObject = array.map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    return (uniqueArray);
  }
  //To set data for Filters Selector
  tempDivisions.push(...filteredUsers.map(x => (x.divisions)).flat(Infinity))
  tempRoles.push(...filteredUsers.map(x => (x.roles)).flat(Infinity))
  tempGroups.push(...filteredUsers.map(x => (x.groups)).flat(Infinity))
  tempLocations.push(...filteredUsers.map(x => (x.locations)).flat(Infinity))
  tempReportsTo.push(...filteredUsers.map(x => (x.reportsTo)).flat(Infinity))

  filteredDivisions = removeDuplicates(tempDivisions)
  filteredRoles = removeDuplicates(tempRoles)
  filteredGroupIDs = JSON.parse(JSON.stringify(removeDuplicates(tempGroups))).map(x => x.id)
  filteredLocationIDs = JSON.parse(JSON.stringify(removeDuplicates(tempLocations))).map(x => x.id)
  filteredReportsToIDs = JSON.parse(JSON.stringify(removeDuplicates(tempReportsTo))).map(x => x.id)

  filteredGroupIDs.forEach(id => {
    filteredGroups.push(...JSON.parse(sessionStorage.getItem('EA-BSA-groups')).filter(x => x.id === id))
  })

  filteredLocationIDs.forEach(id => {
    filteredLocations.push(...JSON.parse(sessionStorage.getItem('EA-BSA-locations')).filter(x => x.id === id))
  })

  filteredReportsToIDs.forEach(id => {
    filteredReportsTo.push(...JSON.parse(sessionStorage.getItem('EA-BSA-users')).filter(x => x.id === id))
  })

  const handleClose1 = () => {
    if (pendingMembers.length > 0)
      setFilteredUsers(pendingMembers); //Fix for SA-51
    else
      setFilteredUsers(entityUsers)
    setAnchorEl(null);
    setOneCheckbox(false);
    setTwoCheckbox(false);
    setThreeCheckbox(false);
    setFourCheckbox(false);
    setFiveCheckbox(false);
    setFilteredValues([]); //Fix for SA-51
  };

  const parametrizedFilteredData = (users) => {
    let filteredRatingUsers = []
    let ratingFilter = []
    console.log(users)
    if (filteredValues.length > 0) {
      filteredValues.forEach(element => {
        ratingFilter = users.filter(x => x.proficiency === element);
        filteredRatingUsers = filteredRatingUsers.concat(ratingFilter)
      })
      console.log(filteredRatingUsers);
      setFilteredUsers(filteredRatingUsers);
      setPendingMembers(users)
    }
    else {
      setFilteredUsers(users)
      setPendingMembers(users)
    }
  }

  const filtersChanged = (filterBy, filterData) => {
    console.log('SkillDetailsPage.filtersChanged.filterBy', filterBy)
    console.log('SkillDetailsPage.filtersChanged.filterData', filterData)
    setFilterBy(filterBy)
    setFilterData(filterData)
    let pendingUsers
    if (filterData.length > 0) {
      let users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
      switch (filterBy) {
        case 'all':
          setFilteredUsers(entityUsers)
          setPendingMembers(entityUsers)
          setOneCheckbox(false);
          setTwoCheckbox(false);
          setThreeCheckbox(false);
          setFourCheckbox(false);
          setFiveCheckbox(false);
          setFilteredValues([])
        break;

        case 'members':
          pendingUsers = location.state.entityType === "Skill" ?
            filterData
              .filter(user => entityUsers.some(x => x.id === user.id))
              .map(user => ({
                ...user,
                division: user.divisions[0].name,
                proficiency: user.skills.find(x => x.id === entityID).ratings
              })) :
            filterData
              .filter(user => entityUsers.some(x => x.id === user.id))
              .map(user => ({
                ...user,
                division: user.divisions[0].name,
                proficiency: user.languages.find(x => x.id === entityID).ratings
              }))
          break;
        
          case 'groups':
          pendingUsers = entityType === 'Skill' ? (users
            .filter(user => user.groups.some(group => filterData.find(x => x.id === group.id)))
            .filter(user => entityUsers.some(x => x.id === user.id))
            .map(user => ({
              ...user,
              division: user.divisions[0].name,
              proficiency: user.skills.find(x => x.id === entityID).ratings
            }))) :
            (users
              .filter(user => user.groups.some(group => filterData.find(x => x.id === group.id)))
              .filter(user => entityUsers.some(x => x.id === user.id))
              .map(user => ({
                ...user,
                division: user.divisions[0].name,
                proficiency: user.languages.find(x => x.id === entityID).ratings
              })))
          break;

        case 'divisions':
          pendingUsers = entityType === 'Skill' ? (users
            .filter(user => user.divisions.some(division => filterData.find(x => x.id === division.id)))
            .filter(user => entityUsers.some(x => x.id === user.id))
            .map(user => ({
              ...user,
              division: user.divisions[0].name,
              proficiency: user.skills.find(x => x.id === entityID).ratings
            }))) :
            (users
              .filter(user => user.divisions.some(division => filterData.find(x => x.id === division.id)))
              .filter(user => entityUsers.some(x => x.id === user.id))
              .map(user => ({
                ...user,
                division: user.divisions[0].name,
                proficiency: user.languages.find(x => x.id === entityID).ratings
              })))
          break;
        
        case 'locations':
          pendingUsers = entityType === 'Skill' ? (users
            .filter(user => user.locations.some(location => filterData.find(x => x.id === location.id)))
            .filter(user => entityUsers.some(x => x.id === user.id))
            .map(user => ({
              ...user,
              division: user.divisions[0].name,
              proficiency: user.skills.find(x => x.id === entityID).ratings
            }))) :
            (users
              .filter(user => user.locations.some(location => filterData.find(x => x.id === location.id)))
              .filter(user => entityUsers.some(x => x.id === user.id))
              .map(user => ({
                ...user,
                division: user.divisions[0].name,
                proficiency: user.languages.find(x => x.id === entityID).ratings
              })))
          break;
        
        case 'reportsto':
          pendingUsers = entityType === 'Skill' ? (users
            .filter(user => user.reportsTo.some(reportsTo => filterData.find(x => x.id === reportsTo.id)))
            .filter(user => entityUsers.some(x => x.id === user.id))
            .map(user => ({
              ...user,
              division: user.divisions[0].name,
              proficiency: user.skills.find(x => x.id === entityID).ratings
            }))) :
            (users
              .filter(user => user.reportsTo.some(reportsTo => filterData.find(x => x.id === reportsTo.id)))
              .filter(user => entityUsers.some(x => x.id === user.id))
              .map(user => ({
                ...user,
                division: user.divisions[0].name,
                proficiency: user.languages.find(x => x.id === entityID).ratings
              })))
          break;
        
        case 'roles':
          pendingUsers = entityType === 'Skill' ? (users
            .filter(user => user.roles.some(role => filterData.find(x => x.id === role.id)))
            .filter(user => entityUsers.some(x => x.id === user.id))
            .map(user => ({
              ...user,
              division: user.divisions[0].name,
              proficiency: user.skills.find(x => x.id === entityID).ratings
            }))) :
            (users
              .filter(user => user.roles.some(role => filterData.find(x => x.id === role.id)))
              .filter(user => entityUsers.some(x => x.id === user.id))
              .map(user => ({
                ...user,
                division: user.divisions[0].name,
                proficiency: user.languages.find(x => x.id === entityID).ratings
              })))
          break;
        default:
          break;
      }
      parametrizedFilteredData(pendingUsers)
    }
    else
      parametrizedFilteredData(entityUsers)
  }

  const filterRating = (selected, labelname) => {
    // if selected add labelname to global array "filteredValues" 
    if (selected === true)
      setFilteredValues([...filteredValues, labelname])
    // if unselected remove labelname from global array "filteredValues"
    else {
      const deleteIndex = filteredValues.findIndex(obj => obj === labelname)
      filteredValues.splice(deleteIndex, 1)
    }
  }

  const getFilteredData = () => {
    // console.log(filteredValues);
    let filteredRatingUsers = []
    let ratingFilter = []
    if (filteredValues.length > 0) {
      filteredValues.forEach(element => {
        ratingFilter = pendingMembers.filter(x => x.proficiency === element);
        filteredRatingUsers = filteredRatingUsers.concat(ratingFilter)
      })
      setFilteredUsers(filteredRatingUsers);
    }
    else 
      setFilteredUsers(pendingMembers);
    setAnchorEl(null);
  }

  const currentlySelected = (params) => {
    //id of the selected users in datagrid is set to selectedUsers state
    setSelectedUsers(params)
    setNumberOfSelectedUsers(params.length)
    // console.log(`SkillDetailsPage.currentlySelected.${params}`)

    if (params.length > 0) {
      setDisableActionsButton(false)
      setDisableDrawerButton(true)
    }
    else {
      setDisableActionsButton(true)
      setDisableDrawerButton(false)
    }
  }


  return (
    <div id="wrap">
      <Header />
      <br/>
      <Breadcrumbs aria-label="breadcrumb" style={{marginLeft:15}}>
        <Link color="primary" href="/skills/skillResults">
          Skills and Languages
        </Link>
        <Typography color="textPrimary">{entityType} / {location.state.entityName}</Typography>
      </Breadcrumbs>
      <br />
      <SkillDetailModal
        open={open}
        handleClose={handleClose}
        modalTitle={modalTitle}
        button1={button1}
        button2={button2}
        entityType={entityType}
        entityID={entityID}
        selectedMembers={selectedUsers}
      />

      <Container style={{ overflow: 'visible' }}>
        <Row>
          <Col>
            <div className="parent">
              <FilterSelector
                members={(location.state.entityType === "Skill") ? (JSON.parse(sessionStorage.getItem('EA-BSA-users')).filter(x => x.skills.find(x => x.id === location.state.entityID))) : (JSON.parse(sessionStorage.getItem('EA-BSA-users')).filter(x => x.languages.find(x => x.id === location.state.entityID)))}
                groups={filteredGroups}
                divisions={filteredDivisions}
                locations={filteredLocations}
                reportsTo={filteredReportsTo}
                roles={filteredRoles}
                selectedFiltersChanged={filtersChanged}
                styles={{
                  root: {
                    width: "35%",
                    marginRight: "5px"
                  }
                }}
              />
              <div className="child3" style={{ marginLeft: 5 }}>
                <Button variant="contained" style={{ textTransform: 'none' }} onClick={e => { setAnchorEl(e.currentTarget); }}><FilterListIcon />Filter</Button>
                <Popover
                  id={id}
                  open={openPop}
                  anchorEl={anchorEl}
                  onClose={e => { setAnchorEl(null) }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <FormControl className="form">
                    <FormControlLabel
                      control={
                        <Checkbox style={{
                          color: "grey", marginLeft: 5, height: "20px",
                          boxSizing: "border-box"
                        }} onChange={e => { setOneCheckbox(e.target.checked); filterRating(e.target.checked, 1) }} checked={oneCheckbox} />} label={<Rating readOnly value={1} size={"medium"} style={{ color: "#444a52" }} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox style={{
                          color: "grey", marginLeft: 5, height: "20px",
                          boxSizing: "border-box"
                        }} onChange={e => { setTwoCheckbox(e.target.checked); filterRating(e.target.checked, 2) }} checked={twoCheckbox} />} label={<Rating readOnly value={2} size={"medium"} style={{ color: "#444a52" }} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox style={{
                          color: "grey", marginLeft: 5, height: "20px",
                          boxSizing: "border-box"
                        }} onChange={e => { setThreeCheckbox(e.target.checked); filterRating(e.target.checked, 3) }} checked={threeCheckbox} />} label={<Rating readOnly value={3} size={"medium"} style={{ color: "#444a52" }} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox style={{
                          color: "grey", marginLeft: 5, height: "20px",
                          boxSizing: "border-box"
                        }} onChange={e => { setFourCheckbox(e.target.checked); filterRating(e.target.checked, 4) }} checked={fourCheckbox} />} label={<Rating readOnly value={4} size={"medium"} style={{ color: "#444a52" }} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox style={{
                          color: "grey", marginLeft: 5, height: "20px",
                          boxSizing: "border-box"
                        }} onChange={e => { setFiveCheckbox(e.target.checked); filterRating(e.target.checked, 5) }} checked={fiveCheckbox} />} label={<Rating readOnly value={5} size={"medium"} style={{ color: "#444a52" }} />}
                    />
                    <div>
                      <Button variant="contained" color = 'primary' style={{ textTransform: 'none', marginLeft: 5, marginBottom: 5 }} onClick={getFilteredData}> Apply</Button>
                      <Button variant="contained" style={{ textTransform: 'none', marginLeft: 5, marginBottom: 5 }} onClick={handleClose1}> Cancel </Button>
                    </div>
                  </FormControl>
                </Popover>
              </div>
            </div>
          </Col>
          <Col>
            <div style={{ float: 'right' }}>
              <Button variant="contained" disabled={disableActionsButton} style={{ textTransform: 'none', marginRight: 5 }} onClick={() => {
                setModaltitle("Do you want to remove the selected " + numberOfSelectedUsers + " members from " + entityType + "?")
                setButton1('Yes')
                setButton2('No')
                handleClickOpen()
              }} >Remove User<DeleteIcon /></Button>
              <Button disabled={disableDrawerButton} onClick={toggleDrawer(true)} variant="contained" style={{ textTransform: 'none' }}><AddCircleIcon />Add members</Button>
              <AddMembersDrawer
                open={openDrawer}
                toggle={toggleDrawer}
                anchor='right'
                entityID={location.state.entityID}
                entityType={location.state.entityType}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <div id='container'>
        <SkillDetailsTable
          rowContent={filteredUsers}
          hiddenColumns={[]}
          styles={{
            root: {
              display: "flex",
              flexDirection: 'column',
            }
          }}
          ratingSize='medium'
          entityType={location.state.entityType}
          entityID={location.state.entityID}
          tableOnClick={currentlySelected}
          resetFilterRatings={getFilteredData}
          resetFilteredMembers={filtersChanged}
          filterBy={filterBy}
          filterData={filterData} />
      </div>
    </div>
  )
}
export default SkillDetailsPage
