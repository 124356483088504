import React, { useEffect, useState } from 'react'
import { Button, Drawer } from '@material-ui/core'
import GroupsSelector from '../groupsSelector/GroupsSelector'
import SelectedGroupMembersTable from '../table/selectedGroupMembersTable'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import utils from '../../services/utils'
import './AddGroupsDrawer.css'
import { PersonAddDisabled } from '@material-ui/icons'

const AddGroupsDrawer = (props) => {
    const { open, toggle, anchor, selectedAgents, toggleCloseDrawer, dynamicgroupsEnabled } = props
    const [data, setData] = useState({ undefined })
    const [loading, setLoading] = useState(false)
    const token = sessionStorage.getItem('purecloud-csp-token')
    const env = sessionStorage.getItem('purecloud-csp-env')
    const [allUsers, setAllUsers] = useState()
    const [selectedUsers, setSelectedUsers] = useState([])
    const [numberOfSelectedUsers, setNumberOfSelectedUsers] = useState([])
    const [selectedGroups, setSelectedGroups] = useState([])
    const [disableAdd, setDisableAdd] = useState(true)
    let selectedMembers = []
    useEffect(() => { // initial default filter on all users for that skill
        try {
            let users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
            setData([])
        } catch (error) {
            console.error('error:', error)
        }
    }, [])

    const removeDuplicates = (array) => {
        let jsonObject = array.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return (uniqueArray);
    }

    const filtersChanged = async (filterBy, filterData) => {
        console.info('AddGroupsDrawer.filtersChanged.filterBy', filterBy)
        console.info('AddGroupsDrawer.filtersChanged.filterData', filterData)
        setSelectedGroups(filterData)
        let pendingUsers = [], users, groups, allGroups, selectedGroups = []
        let groupUsers = []
        let currentGroupUsers = []
        switch (filterBy) {
            case 'allgroups':
                users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
                allGroups = [...JSON.parse(sessionStorage.getItem('EA-BSA-groups')), ...JSON.parse(sessionStorage.getItem('EA-BSA-dynamicgroups'))]

                for (let i = 0; i < filterData.length; i++) {
                    if (filterData[i].type === "dynamic") {
                        setLoading(true)
                        //fetch dynamic group members
                        let allMembersRetrieved = false;
                        let sgMembersListAPIURL = `/api/v2/routing/skillgroups/${filterData[i].id}/members?pageSize=100`;
                        while (!allMembersRetrieved) {
                            try {
                                const getMembersResponse = await utils.fetchWrapper(`https://api.${env}${sgMembersListAPIURL}`,
                                    {
                                        method: "GET",
                                        headers: {
                                            "Content-Type": "application/json",
                                            Authorization: `bearer ${token}`,
                                        },
                                    })
                                if (getMembersResponse && getMembersResponse.entities && getMembersResponse.entities.length > 0) {
                                    currentGroupUsers = currentGroupUsers.concat(getMembersResponse.entities);
                                    if (getMembersResponse.nextUri) {
                                        sgMembersListAPIURL = getMembersResponse.nextUri;
                                    } else {
                                        allMembersRetrieved = true;
                                    }
                                } else allMembersRetrieved = true;
                            } catch (error) {
                                console.error(error)
                            }
                        }
                        console.log("AddGHroupsDrawer.COunt", currentGroupUsers)
                        for (let k = 0; k < currentGroupUsers.length; k++) {
                            if (!groupUsers.find(x => x.id === currentGroupUsers[k].id)) {
                                groupUsers.push(currentGroupUsers[k])
                            }
                        }
                        for (let j = 0; j < groupUsers.length; j++) {
                            if (groupUsers[j].selectedGroups)
                                groupUsers[j].selectedGroups = [...groupUsers[j].selectedGroups, filterData[i].name]
                            else
                                groupUsers[j].selectedGroups = [filterData[i].name]
                        }
                        setLoading(false)
                    }
                    // groupUsers = [...groupUsers, ...filterData[i].users]
                    else
                        pendingUsers = [...pendingUsers, ...users.filter(user => user.groups.find(group => group.id === filterData[i].id))]
                }

                groupUsers = removeDuplicates(groupUsers)
                for (let i = 0; i < groupUsers.length; i++)
                    pendingUsers = [...pendingUsers, ...users.filter(x => x.id === groupUsers[i].id)]


                for (let i = 0; i < pendingUsers.length; i++) {
                    for (let j = 0; j < groupUsers.length; j++) {
                        if (groupUsers[j].id === pendingUsers[i].id)
                            pendingUsers[i].selectedGroups = groupUsers[j].selectedGroups.toString()
                    }
                    for (let j = 0; j < filterData.length; j++) {
                        if (pendingUsers[i].groups.find(x => x.id === filterData[j].id)) {
                            if (pendingUsers[i].selectedGroups === undefined)
                                pendingUsers[i].selectedGroups = allGroups.find(x => x.id === filterData[j].id).name
                            else
                                pendingUsers[i].selectedGroups = pendingUsers[i].selectedGroups + ',' + allGroups.find(x => x.id === filterData[j].id).name

                        }
                    }
                    pendingUsers[i].selectedGroups = removeDuplicates(pendingUsers[i].selectedGroups.split(','))
                }
                pendingUsers = removeDuplicates(pendingUsers)

                // pendingUsers = users
                //     .filter(user => user.groups.find(group => filterData.find(x => x.id === group.id)))
                sessionStorage.setItem('EA-BSA-SelectedGroupMembers', JSON.stringify(pendingUsers))
                console.log('AddGroupsDrawer.Allgroups.pendingUser:', pendingUsers)
                setData([...pendingUsers])
                break;
            case 'staticgroups':
                users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
                allGroups = JSON.parse(sessionStorage.getItem('EA-BSA-groups'))
                pendingUsers = users
                    .filter(user => user.groups.find(group => filterData.find(x => x.id === group.id)))
                for (let i = 0; i < pendingUsers.length; i++) {
                    for (let j = 0; j < filterData.length; j++) {
                        if (pendingUsers[i].groups.find(x => x.id === filterData[j].id)) {
                            if (pendingUsers[i].selectedGroups === undefined)
                                pendingUsers[i].selectedGroups = allGroups.find(x => x.id === filterData[j].id).name
                            else
                                pendingUsers[i].selectedGroups = pendingUsers[i].selectedGroups + ',' + allGroups.find(x => x.id === filterData[j].id).name

                        }
                    }
                    pendingUsers[i].selectedGroups = removeDuplicates(pendingUsers[i].selectedGroups.split(','))
                }
                pendingUsers = removeDuplicates(pendingUsers)
                sessionStorage.setItem('EA-BSA-SelectedGroupMembers', JSON.stringify(pendingUsers))
                console.log('AddGroupsDrawer.Allgroups.pendingUser:', pendingUsers)
                setData([...pendingUsers])
                break;
            case 'dynamicgroups':
                users = JSON.parse(sessionStorage.getItem('EA-BSA-users'))
                allGroups = JSON.parse(sessionStorage.getItem('EA-BSA-dynamicgroups'))
                console.log("AddGroupsDrawer.FiltersChanged", allGroups)

                for (let i = 0; i < filterData.length; i++) {
                    console.log(filterData[i])
                    setLoading(true)
                    let allMembersRetrieved = false;
                    let sgMembersListAPIURL = `/api/v2/routing/skillgroups/${filterData[i].id}/members?pageSize=100`;
                    while (!allMembersRetrieved) {
                        try {
                            const getMembersResponse = await utils.fetchWrapper(`https://api.${env}${sgMembersListAPIURL}`,
                                    {
                                        method: "GET",
                                        headers: {
                                            "Content-Type": "application/json",
                                            Authorization: `bearer ${token}`,
                                        },
                                    })
                            if (getMembersResponse && getMembersResponse.entities && getMembersResponse.entities.length > 0) {
                                currentGroupUsers = currentGroupUsers.concat(getMembersResponse.entities);
                                if (getMembersResponse.nextUri) {
                                    sgMembersListAPIURL = getMembersResponse.nextUri;
                                } else {
                                    allMembersRetrieved = true;
                                }
                            } else allMembersRetrieved = true;
                        } catch (error) {
                            console.error(error)
                        }
                    }
                    console.log("AddGroupsDrawer.FiltersChanged", currentGroupUsers)
                    //groupUsers = removeDuplicates([...groupUsers, ...currentGroupUsers.entities])
                    for (let k = 0; k < currentGroupUsers.length; k++) {
                        if (!groupUsers.find(x => x.id === currentGroupUsers[k].id)) {
                            groupUsers.push(currentGroupUsers[k])
                        }
                    }
                    for (let j = 0; j < groupUsers.length; j++) {
                        // debugger
                        if (groupUsers[j].selectedGroups)
                            // Array(groupUsers[j].selectedGroups).push(filterData[i].name) //
                            groupUsers[j].selectedGroups = [...groupUsers[j].selectedGroups, filterData[i].name]
                        else
                            groupUsers[j].selectedGroups = [filterData[i].name]
                    }
                    setLoading(false)
                }

                //
                groupUsers = removeDuplicates(groupUsers)

                for (let i = 0; i < groupUsers.length; i++)
                    pendingUsers = [...pendingUsers, ...users.filter(x => x.id === groupUsers[i].id)]

                for (let i = 0; i < pendingUsers.length; i++) {
                    // pendingUsers[i].selectedGroups = groupUsers[i].selectedGroups
                    pendingUsers[i].selectedGroups = groupUsers.filter(x=>x.id === pendingUsers[i].id).selectedGroups
                }
                pendingUsers = removeDuplicates(pendingUsers)
                sessionStorage.setItem('EA-BSA-SelectedGroupMembers', JSON.stringify(pendingUsers))
                console.log('AddGroupsDrawer.Allgroups.pendingUser:', pendingUsers)
                setData([...pendingUsers])
                break;
            default:
                break;
        }
    }

    const handleCloseDrawer = () => {
        setData([])
        toggleCloseDrawer(false)
    }
    const handleAddMembers = () => {
        let users = JSON.parse(sessionStorage.getItem('EA-BSA-SelectedGroupMembers'));
        for (let i = 0; i < selectedUsers.length; i++) {
            selectedMembers = [...selectedMembers, ...users.filter(user => user.id === selectedUsers[i])]
        }
        console.log('AddGroupsDrawer.selectedMembers: ', selectedMembers)
        setData([])
        selectedAgents(selectedMembers, selectedGroups)
        toggleCloseDrawer(false)

    }

    const currentlySelected = (params) => {
        //id of the selected users in datagrid is set to selectedUsers state
        setSelectedUsers(params)
        setNumberOfSelectedUsers(params.length)
        console.log('AddGroupsDrawer.CurrentlySelected: ', params)
        if (params.length > 0)
            setDisableAdd(false)
        else
            setDisableAdd(true)
    }

    return (
        <Drawer open={open} anchor={anchor} onClose={toggle(false)}>
            <div hidden={!loading} style={{ position: "fixed", top: "50%", left: "84%" }}>
                <CircularProgress /><br />
            </div>
            <div styles={{ marginLeft: 5, marginBottom: 10 }}>
                <h5>&nbsp;Add Groups</h5>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDrawer}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <GroupsSelector
                allgroups={[...JSON.parse(sessionStorage.getItem('EA-BSA-groups')), ...JSON.parse(sessionStorage.getItem('EA-BSA-dynamicgroups'))]}//change after backend integration
                staticgroups={JSON.parse(sessionStorage.getItem('EA-BSA-groups'))}//change after backend integration
                dynamicgroups={JSON.parse(sessionStorage.getItem('EA-BSA-dynamicgroups'))}//change after backend integration
                selectedFiltersChanged={filtersChanged}
                styles={{
                    root: {
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 2
                    }
                }}
                dynamicgroupsEnabled={dynamicgroupsEnabled}
            />
            <SelectedGroupMembersTable
                rowContent={data}
                hiddenColumns={['groups', 'division']}
                styles={{
                    root: {
                        display: "flex",
                        flexDirection: 'column',
                        marginLeft: 5,
                        marginRight: 5,
                    }
                }}
                tableOnClick={currentlySelected}
                selectedGroups={selectedGroups}
            />
            <div>
                <Button variant="contained" disabled={disableAdd} color='primary' style={{ textTransform: 'none', float: 'left', margin: 5 }} onClick={handleAddMembers}>Add</Button>
                <Button style={{ textTransform: 'none', float: 'left', margin: 5 }} color='primary' onClick={handleCloseDrawer}>Cancel</Button>
            </div>
        </Drawer>
    )
}

export default AddGroupsDrawer